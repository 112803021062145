import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Board } from 'src/app/models/board.model';
import { Plan, User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BoardService } from 'src/app/services/board.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css'],
})
export class LoginDialogComponent implements OnInit {
  @Input() board: Board;
  @Output() closeModal = new EventEmitter();
  @Output() openSignUpModal = new EventEmitter();
  @Output() openResetPasswordModal = new EventEmitter();

  loginForm: FormGroup;
  showError: boolean = false;
  freePlan: Plan = {
    name: 'free',
    period: 'monthly',
    price: 0,
  };
  constructor(
    private fb: FormBuilder,
    private boardService: BoardService,
    private projectService: ProjectService,
    private userService: UserService,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.createForm();
  }
  createForm() {
    this.loginForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.minLength(6)],
      },
      { updateOn: 'submit' }
    );
  }

  ngOnInit(): void {}

  login() {
    if (!this.loginForm.valid) {
      this.showError = true;
      return;
    }

    const credentials = {
      email: this.loginForm.controls['email'].value,
      password: this.loginForm.controls['password'].value,
    };
    this.authService
      .signInWithEmail(credentials)
      .then(async () => {
        if (typeof this.board !== 'undefined') {
          let user: User = await this.userService.getUser();
          const isUserRegisteredInBoard = user.boards.includes(this.board.id);
          if (!isUserRegisteredInBoard) {
            this.http.post(
              environment.nodeUrl + 'notification/new-registered-user',
              {
                board: this.board,
              }
            );

            user.boards.push(this.board.id);
            this.board.registeredUsers.push(user.id);
            await this.userService.setUser(user);
            await this.boardService.setBoard(this.board);
          }
        }

        this.close();
      })
      .catch((err) => {
        this.showError = true;
      });
  }

  signInWithGoogle() {
    this.authService
      .signInWithGoogle()
      .then(async (u) => {
        const isUserRegistered = await this.userService.getUserById(u.user.uid);
        if (!isUserRegistered) {
          const name = u.user.displayName.split(' ');
          const registeredUser: User = {
            email: u.user.email,
            id: u.user.uid,
            boards: [],
            comments: [],
            firstname: name[0],
            surname: name[1],
            projects: [],
            upvotes: [],
            createdPosts: [],
            registeredDate: new Date(),
            plan: this.freePlan,
            firstLogin: true,
            notification:{
              newComment: true,
              newStatus: true,
              newTag: true
            }
          };
          await this.userService.setUser(registeredUser);
        }

        if (typeof this.board !== 'undefined') {
          let user: User = await this.userService.getUser();
          const isUserRegisteredInBoard = user.boards.includes(this.board.id);
          if (!isUserRegisteredInBoard) {
            this.http.post(
              environment.nodeUrl + 'notification/new-registered-user',
              {
                board: this.board,
              }
            );
            user.boards.push(this.board.id);
            this.board.registeredUsers.push(user.id);
            await this.userService.setUser(user);
            await this.boardService.setBoard(this.board);
          }
        }

        this.close();
      })
      .catch((err) => {
        this.showError = true;
      });
  }

  signInWithGithub() {
    this.authService
      .signInWithGithub()
      .then(async (u) => {
        const isUserRegistered = await this.userService.getUserById(u.user.uid);
        if (!isUserRegistered) {
          const name = u.user.displayName.split(' ');
          const registeredUser: User = {
            email: u.user.email,
            id: u.user.uid,
            boards: [],
            comments: [],
            firstname: name[0],
            surname: name[1],
            projects: [],
            upvotes: [],
            createdPosts: [],
            registeredDate: new Date(),
            plan: this.freePlan,
            firstLogin: true,
            notification:{
              newComment: true,
              newStatus: true,
              newTag: true
            }
          };
          await this.userService.setUser(registeredUser);
        }

        if (typeof this.board !== 'undefined') {
          let user: User = await this.userService.getUser();
          const isUserRegisteredInBoard = user.boards.includes(this.board.id);
          if (!isUserRegisteredInBoard) {
            this.http.post(
              environment.nodeUrl + 'notification/new-registered-user',
              {
                board: this.board,
              }
            );
            user.boards.push(this.board.id);
            this.board.registeredUsers.push(user.id);
            await this.userService.setUser(user);
            await this.boardService.setBoard(this.board);
          }
        }

        this.close();
      })
      .catch((err) => {
        this.showError = true;
      });
  }

  close() {
    this.closeModal.emit(true);
  }

  openSignUp() {
    this.openSignUpModal.emit(true);
  }

  openResetPassword() {
    this.openResetPasswordModal.emit(true);
  }
}
