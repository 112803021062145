<app-login-dialog
  *ngIf="showLoginModal"
  (openSignUpModal)="showRegisterModal = true; showLoginModal = false"
  (closeModal)="closeLoginModal()"
  (openResetPasswordModal)="showForgotPassword = true; showLoginModal = false"
  [board]="board"
></app-login-dialog>

<app-register-dialog
  *ngIf="showRegisterModal"
  (openSignInModal)="showLoginModal = true; showRegisterModal = false"
  (closeModal)="closeRegisterModal()"
  [board]="board"
></app-register-dialog>

<app-forgot-password
  *ngIf="showForgotPassword"
  (closeModal)="showForgotPassword = false"
></app-forgot-password>
<nav class="w-full bg-transparent">
  <div class="m-4 h-16 flex justify-between">
    <div class="flex items-center">
      <img
        *ngIf="board?.settings.fbLogoVisibility && project"
        (click)="openFeatureBear()"
        [src]="
          project?.settings?.logo
            ? project?.settings?.logo
            : 'assets/fb-red.png'
        "
        class="cursor-pointer pl-4 w-auto h-12"
      />
    </div>
    <ul class="md:flex hidden items-center h-full">
      <li
        class="cursor-pointer h-full flex items-center text-sm text-gray-800 px-3 tracking-normal relative"
        (click)="dropdownHandler()"
      >
        <ul
          *ngIf="showBoardsMenu"
          class="bg-white shadow rounded py-1 w-32 left-0 mt-16 -ml-4 absolute top-0"
        >
          <li
            (click)="openBoard(board)"
            class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-400 hover:text-white px-3 font-normal"
            *ngFor="let board of boards"
          >
            {{ board.name }}
          </li>
        </ul>
        Boards
        <span class="ml-2 text-gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-chevron-down"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <polyline points="6 9 12 15 18 9" />
          </svg>
        </span>
      </li>
      <li
        (click)="openChangelog()"
        class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
        [ngClass]="{
          'text-fb-red': active.includes('changelog')
        }"
      >
        Changelog
      </li>
      <li
        (click)="openRoadmap()"
        class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
        [ngClass]="{
          'text-fb-red': active.includes('roadmap')
        }"
      >
        Roadmap
      </li>
    </ul>
    <div class="relative">
      <div class="relative flex items-center cursor-pointer">
        <div
          class="flex cursor-pointer items-center"
          (click)="loginDropdown = !loginDropdown"
        >
          <svg
            class="h-6 w-6 m-4 text-gray-600"
            *ngIf="user"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p *ngIf="user" class="text-gray-600 mr-4 text-sm">
            {{ user?.firstname }} {{ user?.surname }}
          </p>
        </div>

        <svg
          *ngIf="!user"
          (click)="loginDropdown = !loginDropdown"
          class="h-6 w-6 m-4 text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
          />
        </svg>
      </div>
      <ul
        *ngIf="loginDropdown"
        class="bg-white shadow rounded mt-2 -ml-24 py-1 w-48 absolute"
      >
        <li
          *ngIf="!user"
          (click)="showLoginModal = true"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Login
        </li>
        <li
          *ngIf="!user"
          (click)="showRegisterModal = true"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Register
        </li>
        <li
          *ngIf="user"
          (click)="openSettings()"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Settings
        </li>
        <li
          *ngIf="user"
          (click)="logout()"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Logout
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="md:hidden flex justify-center mb-10">
  <ul class="flex items-center h-full">
    <li
      class="cursor-pointer h-full flex items-center text-sm text-gray-800 px-3 tracking-normal relative"
      (click)="dropdownHandler()"
    >
      <ul
        *ngIf="showBoardsMenu"
        class="bg-white shadow rounded py-1 w-32 left-0 mt-16 -ml-4 absolute top-0"
      >
        <li
          (click)="openBoard(board)"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-400 hover:text-white px-3 font-normal"
          *ngFor="let board of boards"
        >
          {{ board.name }}
        </li>
      </ul>
      Boards
      <span class="ml-2 text-gray-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-chevron-down"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </span>
    </li>
    <li
      (click)="openChangelog()"
      class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
      [ngClass]="{
        'text-fb-red': active.includes('changelog')
      }"
    >
      Changelog
    </li>
    <li
      (click)="openRoadmap()"
      class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
      [ngClass]="{
        'text-fb-red': active.includes('roadmap')
      }"
    >
      Roadmap
    </li>
  </ul>
</div>

<div class="flex flex-no-wrap">
  <div class="py-8 px-12 w-full">
    <div
      (click)="back()"
      class="cursor-pointer w-20 mb-6 p-1 border rounded-xl flex items-center"
    >
      <svg
        class="cursor-pointer w-4 h-4 mr-2 text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 16l-4-4m0 0l4-4m-4 4h18"
        />
      </svg>
      <p class="cursor-pointer text-gray-700 font-medium text-sm pr-3">back</p>
    </div>

    <div *ngIf="loader">
      <div class="my-2 rounded-xl animate-pulse h-10 w-full bg-gray-300"></div>
    </div>
    <div *ngIf="!loader" class="flex items-center justify-between mt-10 mb-5">
      <h1 class="text-5xl font-bold text-gray-900" *ngIf="tag">
        {{ tag?.name }}-Tag
      </h1>
    </div>

    <div *ngIf="loader">
      <div class="my-2 rounded-xl animate-pulse h-32 w-full bg-gray-300"></div>
      <div class="my-2 rounded-xl animate-pulse h-32 w-full bg-gray-300"></div>
      <div class="my-2 rounded-xl animate-pulse h-32 w-full bg-gray-200"></div>
      <div class="my-2 rounded-xl animate-pulse h-32 w-full bg-gray-100"></div>
      <div class="my-2 rounded-xl animate-pulse h-32 w-full bg-gray-50"></div>
    </div>

    <div class="mt-20" *ngIf="posts?.length === 0">
      <h1 class="text-gray-500 text-xl">It's empty here</h1>
      <h2 class="text-gray-500 text-md"></h2>
    </div>

    <div
      class="flex justify-between flex-col md:flex-row md:space-x-10 space-y-4 sm:-space-y-0"
    >
      <div class="relative w-full md:w-1/4">
        <div
          (click)="this.show_list = !this.show_list"
          class="relative z-0 bg-gray-100 bg-white flex items-center justify-between rounded w-full cursor-pointer text-xs form-select block p-4 rounded bg-transparent"
          data-menu
        >
          <p
            class="leading-3 tracking-normal font-normal text-gray-600 text-sm"
          >
            Sort:
            <span class="text-indigo-700 mr-2">{{ activeSort }}</span>
          </p>
          <div class="cursor-pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="hidden icon icon-tabler icon-tabler-chevron-up"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="6 15 12 9 18 15" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-chevron-up"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </div>
        </div>
        <ul
          *ngIf="show_list"
          class="z-10 bg-white transition duration-300 bg-white shadow rounded mt-2 w-40 py-1 absolute"
        >
          <li
            (click)="sortBy('Upvotes')"
            class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
          >
            Upvotes
          </li>
          <li
            (click)="sortBy('Latest')"
            class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-100 px-3 font-normal"
          >
            Latest
          </li>
        </ul>
      </div>

      <div class="flex w-full">
        <div class="w-full">
          <div class="border border-gray-400 relative flex items-center">
            <div class="absolute ml-4 cursor-pointer">
              <svg
                width="14px"
                height="14px"
                viewBox="0 0 14 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    transform="translate(-196.000000, -4105.000000)"
                    fill="#718096"
                    fill-rule="nonzero"
                  >
                    <g transform="translate(152.000000, 4017.000000)">
                      <g transform="translate(32.000000, 72.000000)">
                        <g>
                          <g>
                            <path
                              d="M22.0506576,25.0542101 L25.8342802,28.8449993 C26.0709604,29.125199 26.0520423,29.5403831 25.7908611,29.7978957
                                                      C25.5296798,30.0554083 25.1142853,30.0684363 24.8374817,29.8277965 L21.0608788,26.0510473 C18.7190814,27.8702712
                                                      15.3664691,27.5551481 13.4046533,25.3314133 C11.4428375,23.1076785 11.5480113,19.7418025 13.6448344,17.6448982
                                                      C15.7416575,15.5479938 19.107403,15.4428159 21.3310516,17.4047078 C23.5547002,19.3665996 23.8698111,22.7193419
                                                      22.0506576,25.0612301 L22.0506576,25.0542101 Z M17.5263158,25.5789474 C19.7645224,25.5789474 21.5789474,23.7645224
                                                      21.5789474,21.5263158 C21.5789474,19.2881092 19.7645224,17.4736842 17.5263158,17.4736842 C15.2881092,17.4736842
                                                      13.4736842,19.2881092 13.4736842,21.5263158 C13.4736842,23.7645224 15.2881092,25.5789474 17.5263158,25.5789474 Z"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <input
              type="text"
              placeholder="Search"
              class="focus:outline-none pl-10 w-full h-full py-3 text-sm"
              id="Search"
              aria-label="Search"
              [(ngModel)]="filterText"
              (input)="filterList()"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      *ngFor="let post of posts"
      class="bg-white border border-gray-200 hover:shadow-lg duration-500 pt-1 my-8"
    >
      <div
        class="flex sm:flex-row flex-col sm:items-center sm:justify-start justify-center items-center"
      >
        <div class="flex-none w-full p-2 md:w-24 mb-4 text-center">
          <a
            (click)="upvote(post)"
            [ngClass]="{
              'bg-green-500 text-white': user?.upvotes.includes(post.id),
              'bg-green-50 ': !user?.upvotes.includes(post.id)
            }"
            class="group flex flex-col justify-center items-center py-1 w-full border rounded-md md:hover:bg-green-500 cursor-pointer"
          >
            <svg
              class="md:group-hover:text-white w-6"
              [ngClass]="{
                'text-white': user?.upvotes.includes(post.id),
                'text-green-400': !user?.upvotes.includes(post.id)
              }"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 15l7-7 7 7"
              />
            </svg>
            <div
              class="inline-block font-medium text-lg md:group-hover:text-white"
              [ngClass]="{
                'text-white': user?.upvotes.includes(post.id),
                'text-green-400': !user?.upvotes.includes(post.id)
              }"
            >
              {{ post.upvotes.length }}
            </div>
          </a>
        </div>

        <div class="flex flex-col w-full flex-grow">
          <div
            class="md:-mb-4 space-x-2 sm:px-0 mt-1 px-4 hidden w-full md:flex items-center md:justify-start justify-between flex-row cursor-pointer"
          >
            <div class="flex mr-2">
              <svg
                class="w-4 mr-1 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span class="text-xs text-gray-400">
                {{ post?.date_created.toDate() | date: "short" }}</span
              >
            </div>
            <div class="flex">
              <svg
                class="text-sm text-gray-400 w-4 mr-1"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
              <div class="font-medium text-xs text-gray-400">
                {{ post.creator | user | async }}
              </div>
            </div>
            <div class="flex">
              <div
                class="border h-5 w-auto px-2 mb-4 md:mb-0 rounded-md flex items-center justify-center"
              >
                <div class="flex items-center">
                  <span class="text-xs text-gray-500 font-normal">{{
                    post.status
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            (click)="openPost(post.id)"
            class="md:mt-5 -mt-4 justify-center text-center md:text-left cursor-pointer"
          >
            <div
              class="md:hidden xs:flex flex-col h-5 px-2 mb-4 md:mb-0 rounded-md items-center justify-center"
            >
              <div class="flex justify-center items-center">
                <span
                  class="border px-2 justify-center text-center text-xs text-gray-500 font-normal"
                  >{{ post.status }}</span
                >
              </div>
            </div>
            <a class="sm:text-sm md:text-md lg:text-lg text-gray-700 font-bold">
              {{ post.title }}
            </a>
          </div>
          <div
            class="mt-2 md:mb-2 flex flex-row md:justify-start justify-center"
          >
            <div class="col-span-12 lg:col-span-8 mb-2">
              <a
                *ngFor="let tag of post.tags"
                [ngStyle]="{
                  'background-color': tag | tagColor | async
                }"
                (click)="openTag(tag)"
                class="cursor-pointer inline-block rounded-full text-white duration-300 text-xs font-bold mr-1 md:mr-2 px-2 md:px-4 py-1 opacity-90 hover:opacity-100"
              >
                {{ tag | tag | async }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
