<div
  id="member-modal"
  class="fixed z-10 pt-10 left-0 top-0 w-full h-full overflow-auto shadow-lg display: none;"
  style="background-color: rgb(0, 0, 0); background-color: rgba(0, 0, 0, 0.4)"
>
  <div role="alert" class="container mx-auto w-11/12 md:w-2/3 max-w-lg">
    <div
      class="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400"
    >
      <div class="w-full flex justify-start text-gray-600 mb-3">
        <img src="assets/fba-logo.png" class="h-12 w-auto" />
      </div>
      <form [formGroup]="registerForm"  (ngSubmit)="register()" novalidate>
        <h1
          class="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4"
        >
          Sign Up
        </h1>
        <label
          for="email"
          class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
        >
          Email</label
        >
        <input
          id="email"
          formControlName="email"
          [ngClass]="{
            'border-red-400':
              registerForm.controls['email'].invalid &&
              (registerForm.controls['email'].dirty ||
                registerForm.controls['email'].touched)
          }"
          class="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
          placeholder="your@mail.com"
          type="email"
        />
        <label
          for="password"
          class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
        >
          Password</label
        >
        <input
          id="password"
          formControlName="password"
          type="password"
          placeholder="min. 8 characters"
          [ngClass]="{
            'border-red-400':
              registerForm.controls['password'].invalid &&
              (registerForm.controls['password'].dirty ||
                registerForm.controls['password'].touched)
          }"
          class="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
        />

        <div *ngIf="showError" class="flex justify-evenly">
          <div class="mr-2 mb-1 xl:mb-0 text-red-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="20"
              height="20"
              fill="currentColor"
            >
              <path
                class="heroicon-ui"
                d="M4.93 19.07A10 10 0 1 1 19.07 4.93 10 10 0 0 1 4.93 19.07zm1.41-1.41A8 8 0 1 0 17.66 6.34 8 8 0 0 0 6.34 17.66zM13.41 12l1.42 1.41a1 1 0 1 1-1.42 1.42L12 13.4l-1.41 1.42a1 1 0 1 1-1.42-1.42L10.6 12l-1.42-1.41a1 1 0 1 1 1.42-1.42L12 10.6l1.41-1.42a1 1 0 1 1 1.42 1.42L13.4 12z"
              />
            </svg>
          </div>

          <p class="text-sm sm:text-base text-gray-600 pb-2 sm:pb-0">
            The data you entered is incorrect
          </p>
        </div>
        <div class="flex items-center justify-center w-full">
          <button
           type="submit"
            class="focus:outline-none transition duration-150 ease-in-out bg-fb-red rounded text-white px-8 py-2 text-sm"
          >
            Sign Up For Free
          </button>
        </div>
      </form>
      <p class="text-gray-400 mt-4 text-center text-sm">or use</p>
      <div class="flex mt-4 justify-between">
        <img
          src="assets/google.png"
          class="h-10 w-auto cursor-pointer"
          (click)="signInWithGoogle()"
        />
        <div
          class="w-1/3 border rounded-lg cursor-pointer space-x-3 text-gray-600 flex items-center"
          (click)="signInWithGithub()"
        >
          <img src="assets/github.png" class="h-10 w-auto" />
          <p>Github</p>
        </div>
      </div>
      <div class="flex mt-3 items-center justify-center w-full">
        <button
          (click)="openLogin()"
          class="focus:outline-none ml-3 transition duration-150 ease-in-out text-fb-red rounded px-8 py-2 text-sm"
        >
          You Already Have An Account? Sign In
        </button>
      </div>
      <svg
        (click)="close()"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Close"
        class="close-member cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out icon icon-tabler icon-tabler-x"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        stroke-width="2.5"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </div>
  </div>
</div>
