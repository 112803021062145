import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  showError: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) {
    this.createForm();
  }
  createForm() {
    this.registerForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]],
      },
      { updateOn: 'blur' }
    );
  }

  ngOnInit(): void {}

  register() {
    console.log(this.registerForm);

    if (!this.registerForm.valid) {
      this.showError = true;
      return;
    }

    const credentials = {
      email: this.registerForm.controls['email'].value,
      password: this.registerForm.controls['password'].value,
    };

    this.authService
      .signUp(credentials)
      .then(async (u) => {
        const registeredUser: User = {
          email: u.user.email,
          id: u.user.uid,
          boards: [],
          comments: [],
          firstname: '',
          surname: '',
          projects: [],
          upvotes: [],
          createdPosts: [],
          firstLogin: true,
          plan: { name: 'free', price: 0, period: 'monthly' },
          registeredDate: new Date(),
          notification: { newTag: true, newStatus: true, newComment: true },

        };

        const isUserRegistered = await this.userService.getUserById(u.user.uid);
        if (isUserRegistered) {
          await this.userService.setUser(registeredUser);
        } else {
          await this.userService.setUser(registeredUser);
        }

        this.router.navigate(['/project']);
      })
      .catch(() => {
        this.showError = true;
      });
  }

  openLogin() {
    this.router.navigate(['/login']);
  }
}
