import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Changelog } from 'src/app/models/changelog.model';
import { Post } from 'src/app/models/post.model';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BoardService } from 'src/app/services/board.service';
import { ChangelogService } from 'src/app/services/changlog.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { Roadmap } from 'src/app/models/roadmap.model';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexPlotOptions,
  ApexXAxis,
  ApexFill,
  ApexDataLabels,
  ApexYAxis,
  ApexGrid,
} from 'ng-apexcharts';
import { RoadmapService } from 'src/app/services/roadmap.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.css'],
})
export class RoadmapComponent implements OnInit {
  flag = true;
  showAddFeature: boolean = false;
  showMenu: boolean = false;
  boardId: string;
  changelogId: string;
  board: Board;
  posts: Post[];
  originalPosts: Post[];
  loader: boolean = false;
  user: User;
  boards: Board[] = [];
  showBoardSettings: boolean = false;
  loginDropdown: boolean = false;
  showLoginModal: boolean = false;
  showRegisterModal: boolean = false;
  isAdmin: boolean = false;
  show_list = false;
  filterText: string = '';
  activeSort: string = 'Upvotes';
  showBoardList: boolean = false;
  project: Project;
  showForgotPassword: boolean = false;
  changelogs: Changelog[] = [];
  showBoardsMenu: boolean = false;
  active: string = '';


  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  projectId: string;
  roadmapItems: Roadmap[];
  menu: any;
  modalData = {
    header: 'Delete Roadmap Item',
    description: 'Do you want to delete this Roadmap Item?',
    yes: 'Yes',
    no: 'No',
  };

  showModal: boolean = false;
  currentRoadmap: Roadmap;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private boardService: BoardService,
    private userService: UserService,
    private projectService: ProjectService,
    private authService: AuthService,
    private http: HttpClient,
    private changelogService: ChangelogService,
    private roadmapService: RoadmapService
  ) {
    this.active = router.url;
  }

  async ngOnInit() {
    this.loader = true;
    this.boardId = this.route.snapshot.paramMap.get('id');
    this.changelogId = this.route.snapshot.paramMap.get('changelogId');

    if (this.boardId === null) {
      window.location.href = 'https://featurebear.com';
    }

    this.board = await this.boardService.getBoard(this.boardId);

    this.loadBoards();
    this.loadRoadmaps();

    if (typeof this.board === 'undefined') {
      window.location.href = 'https://featurebear.com';
    }

    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
    this.isAdmin = this.user ? this.board.admins.includes(this.user.id) : false;
  }

  dropdownHandler() {
    this.showBoardsMenu = !this.showBoardsMenu;
  }

  openChangelog() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['/' + this.boardId + '/changelog']);
  }

  openRoadmap(){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['/' + this.boardId + '/roadmap']);
  }

  async loadRoadmaps() {
    this.roadmapItems = await this.roadmapService.getRoadmapsOfProject(
      this.board.projectID
    );
    let chartdata = [];
    for (let ri of this.roadmapItems) {
      let chartItem = {
        x: ri.name,
        y: [
          new Date(ri.startDate['seconds'] * 1000).getTime(),
          new Date(ri.endDate['seconds'] * 1000).getTime(),
        ],
        fillColor: ri.color,
      };
      chartdata.push(chartItem);
    }

    this.chartOptions = {
      series: [
        {
          data: chartdata,
        },
      ],
      chart: {
        height: 350,
        width: '85%',
        type: 'rangeBar',
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          dataLabels: {
            hideOverflowingLabels: false,
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          var label = opts.w.globals.labels[opts.dataPointIndex];
          return label;
        },
        style: {
          colors: ['#f3f4f5', '#fff'],
        },
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        show: false,
      },
      grid: {
        row: {
          colors: ['#f3f4f5', '#fff'],
          opacity: 1,
        },
      },
    };
  }

  async loadBoards() {
    this.project = await this.projectService.getProject(this.board.projectID);

    if (typeof this.changelogId === 'undefined' || this.changelogId === null) {
      this.project.changelog = this.project.changelog || [];

      for (let changelogId of this.project.changelog) {
        let changelogItem = await this.changelogService.getChangelog(
          changelogId
        );
        if (changelogItem.status) {
          this.changelogs.push(changelogItem);
        }
      }
    } else {
      let changelogItem = await this.changelogService.getChangelog(
        this.changelogId
      );
      if (changelogItem.status) {
        this.changelogs.push(changelogItem);
      }
    }
    this.changelogs.reverse();
    this.loader = false;

    this.project.settings = this.project.settings || {
      linkBoards: false,
      logo: '',
    };

    for (let boardId of this.project.boards) {
      const projectBoard = await this.boardService.getBoard(boardId);
      if (projectBoard.settings.visibility) {
        this.boards.push(projectBoard);
      }
    }
  }

  openPost(postId) {
    this.router.navigate(['/post/' + postId]);
  }

  openBoard(board: Board) {
    this.router.navigate(['/' + board.id]);
  }

  async closeSettings() {
    this.showBoardSettings = false;
    //await this.loadPosts();
  }

  openFeatureBear() {
    window.open('https://featurebear.com', '_blank');
  }

  async closeLoginModal() {
    this.showLoginModal = false;
    this.loginDropdown = false;
    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
  }

  async closeRegisterModal() {
    this.showRegisterModal = false;
    this.loginDropdown = false;
    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
  }

  openSettings() {
    this.router.navigate(['/settings/' + this.board.id]);
  }

  async logout() {
    await this.authService.signOut();
    this.user = null;
    this.loginDropdown = false;
  }

  getEncodedUrl(changelogId) {
    return window.encodeURIComponent(
      'https://app.featurebear.com/' +
        this.boardId +
        '/changelog/' +
        changelogId
    );
  }

  copyUrl(changelogId) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value =
      'https://app.featurebear.com/' +
      this.boardId +
      '/changelog/' +
      changelogId;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    var Notification = document.getElementById('notification');
    Notification.style.transform = 'translateX(150%)';
    Notification.classList.remove('hidden');
    Notification.style.transform = 'translateX(0%)';
    setTimeout(() => {
      this.closeModal();
    }, 3000);
  }

  closeModal() {
    var Notification = document.getElementById('notification');
    Notification.style.transform = 'translateX(150%)';
    Notification.classList.add('hidden');
  }
}
