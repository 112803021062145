<app-add-board
  (closeModal)="closeModal()"
  [projectId]="projectId"
  *ngIf="showAddBoardModal"
></app-add-board>

<app-project-settings
  (closeSettingsModal)="closeSettingsModal()"
  *ngIf="showProjectSettings"
  [project]="project"
></app-project-settings>

<div class="flex flex-no-wrap">
  <app-sidebar [boards]="boards"></app-sidebar>
  <div class="py-8 px-10 md:px-12 w-full">
    <div
      (click)="back()"
      class="cursor-pointer w-20 mb-6 p-1 border rounded-xl flex items-center"
    >
      <svg
        class="cursor-pointer w-4 h-4 mr-2 text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 16l-4-4m0 0l4-4m-4 4h18"
        />
      </svg>
      <p class="cursor-pointer text-gray-700 font-medium text-sm pr-3">back</p>
    </div>
    <div class="flex items-center justify-between mt-10 mb-5">
      <h1 class="text-5xl font-bold text-gray-900">
        {{ project?.name }}-Project
      </h1>

      <div
        (click)="addBoard()"
        class="cursor-pointer w-10 h-10 bg-fb-red text-white rounded-full flex justify-center items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-plus"
          width="28"
          height="28"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1="12" y1="5" x2="12" y2="19" />
          <line x1="5" y1="12" x2="19" y2="12" />
        </svg>
      </div>
    </div>

    <ul
      class="flex flex-col md:flex-row items-start md:items-center text-gray-600 text-sm mt-3"
    >
      <li class="flex items-center mt-4 md:mt-0">
        <a
          (click)="showProjectSettings = true"
          class="my-2 flex items-center bg-gray-100 transition duration-150 ease-in-out hover:bg-gray-200 rounded border border-gray-300 text-gray-600 pl-3 pr-6 py-2 text-sm cursor-pointer"
        >
          <span class="h-4 w-4 mr-2">
            <svg
              xmlns="www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </span>
          Settings
        </a>
      </li>
    </ul>

    <div
      class="grid sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-3"
    >
      <div
        *ngFor="let board of boards"
        class="mx-auto container py-4 px-6 -ml-6"
      >
        <div class="w-full">
          <div
            (click)="openBoard(board.id)"
            class="w-full cursor-pointer bg-white hover:shadow-xl rounded-lg shadow-lg mb-3 py-5 px-4"
          >
            <div class="flex flex-row justify-between">
              <h4 class="text-gray-800 font-semibold mb-4">{{ board.name }}</h4>
              <div class="flex items-center flex-no-wrap">
                <div class="w-12 h-12 bg-cover bg-center rounded-md">
                  <img
                    [src]="
                      board.settings.logo
                        ? board.settings.logo
                        : 'assets/fb-logo-badge.png'
                    "
                    alt=""
                    class="h-full w-full overflow-hidden object-cover rounded-full border-2 border-white shadow"
                  />
                </div>
              </div>
            </div>

            <p class="mb-3 text-gray-600 text-sm">{{ board.description }}</p>
            <div class="flex items-center justify-between text-gray-600">
              <div
                class="mb-5 rounded-full border border-gray-400 py-1 pl-2 pr-3 flex items-center"
              >
                <p class="ml-2 text-xs">{{ board.posts.length }} Posts</p>
              </div>
            </div>
            <div class="mt-4 flex justify-between items-center text-gray-600">
              <div>
                <p class="text-gray-400 text-sm">
                  {{ board.registeredUsers.length }} Registered Users
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
