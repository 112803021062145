import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Post } from 'src/app/models/post.model';
import { User } from 'src/app/models/user.model';
import { Comment } from 'src/app/models/comment.model';
import { AuthService } from 'src/app/services/auth.service';
import { BoardService } from 'src/app/services/board.service';
import { CommentService } from 'src/app/services/comment.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Project } from 'src/app/models/project.model';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.css'],
})
export class PostDetailComponent implements OnInit {
  postId: string;
  board: Board;
  user: User;
  post: Post;
  boards: Board[] = [];
  showLoginModal: boolean = false;
  loginDropdown: boolean = false;
  showRegisterModal: boolean = false;
  showComments: boolean = true;
  commentText: string = '';
  comments: Comment[] = [];
  showForgotPassword: boolean = false;
  project: Project;
  showBoardsMenu: boolean = false;
  active: string = '';
  widget: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private boardService: BoardService,
    private userService: UserService,
    private postService: PostService,
    private authService: AuthService,
    private commentService: CommentService,
    private http: HttpClient,
    private projectService: ProjectService
  ) {
    this.active = router.url;
    this.route.queryParams.subscribe((params) => {
      this.widget = params['widget'];
    });
  }

  async ngOnInit() {
    this.postId = this.route.snapshot.paramMap.get('id');
    this.post = await this.postService.getPost(this.postId);
    this.board = await this.boardService.getBoard(this.post.boardId);
    this.project = await this.projectService.getProject(this.board.projectID);
    await this.loadComments();

    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
    this.loadBoards();
  }

  async loadBoards() {
    this.project = await this.projectService.getProject(this.board.projectID);

    for (let boardId of this.project.boards) {
      const projectBoard = await this.boardService.getBoard(boardId);
      if (projectBoard.settings.visibility) {
        this.boards.push(projectBoard);
      }
    }
  }

  openBoard(board: Board) {
    this.router.navigate(['/' + board.id]);
  }

  async upvote(post: Post) {
    if (!this.user) {
      this.showLoginModal = true;
      return;
    }

    const hasUserUpvoted = post.upvotes.includes(this.user.id);

    if (hasUserUpvoted) {
      post.upvotes = post.upvotes.filter((userId) => userId !== this.user.id);
      this.user.upvotes = this.user.upvotes.filter(
        (postId) => postId !== post.id
      );
    } else {
      this.http.post(environment.nodeUrl + 'notification/new-upvote', {
        board: this.board,
        post: post,
      });
      post.upvotes.push(this.user.id);
      this.user.upvotes.push(post.id);
    }
    await this.postService.setPost(post);
    await this.userService.setUser(this.user);
  }

  navigateBack() {
    let widgetParam = this.widget ? 'true' : '';
    this.router.navigate(['/' + this.post.boardId], {
      queryParams: { widget: widgetParam },
    });
  }

  dropdownHandler() {
    this.showBoardsMenu = !this.showBoardsMenu;
  }

  openChangelogOfPost() {
    this.router.navigate([
      '/' + this.board.id + '/changelog/' + this.post.changelog,
    ]);
  }

  openChangelog() {
    this.router.navigate(['/' + this.board.id + '/changelog/']);
  }

  async loadComments() {
    this.comments = [];
    for (let commentId of this.post.comments) {
      this.comments.push(await this.commentService.getComment(commentId));
    }
  }

  async closeLoginModal() {
    this.showLoginModal = false;
    this.loginDropdown = false;
    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
  }

  async closeRegisterModal() {
    this.showRegisterModal = false;
    this.loginDropdown = false;
    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
  }

  async logout() {
    await this.authService.signOut();
    this.user = null;
    this.loginDropdown = false;
  }

  openSettings() {
    this.router.navigate(['/settings/' + this.board.id]);
  }

  openRoadmap() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['/' + this.board.id + '/roadmap']);
  }

  openFeatureBear() {
    window.open('https://featurebear.com', '_blank');
  }

  openTag(tagId) {
    this.router.navigate(['/tag/' + tagId]);
  }

  async addComment() {
    if (!this.user) {
      this.showLoginModal = true;
      return;
    }
    if (this.commentText === '') return;

    let comment: Comment = {
      comment: this.commentText,
      date: new Date(),
      user: this.user.id,
    };

    const commmentId = await this.commentService.createComment(comment);
    this.post.comments.push(commmentId);

    this.http
      .post(environment.nodeUrl + 'notification/new-comment', {
        board: this.board,
        post: this.post,
        currentUser: this.user,
      })
      .subscribe((t) => console.log(t));

    await this.postService.setPost(this.post);

    this.user.comments.push(commmentId);
    await this.userService.setUser(this.user);

    await this.loadComments();
    this.commentText = '';
  }

  openImage(image) {
    var win = window.open(image, '_blank');
    win.focus();
  }
}
