import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Post } from 'src/app/models/post.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { Timestamp } from 'rxjs';
import { Project } from 'src/app/models/project.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
})
export class BoardComponent implements OnInit {
  flag = true;
  showAddFeature: boolean = false;
  showMenu: boolean = false;
  boardId: string;
  board: Board;
  posts: Post[];
  originalPosts: Post[];
  loader: boolean = false;
  user: User;
  boards: Board[] = [];
  showBoardSettings: boolean = false;
  loginDropdown: boolean = false;
  showLoginModal: boolean = false;
  showRegisterModal: boolean = false;
  isAdmin: boolean = false;
  show_list = false;
  filterText: string = '';
  activeSort: string = 'Upvotes';
  showBoardList: boolean = false;
  project: Project;
  showForgotPassword: boolean = false;
  showBoardsMenu: boolean = false;
  active: string = '';
  widget: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private boardService: BoardService,
    private userService: UserService,
    private postService: PostService,
    private projectService: ProjectService,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.active = router.url;
    this.route.queryParams.subscribe((params) => {
      this.widget = params['widget'];
    });
  }

  async ngOnInit() {
    this.loader = true;
    this.boardId = this.route.snapshot.paramMap.get('id');

    if (this.boardId === null) {
      window.location.href = 'https://featurebear.com';
    }

    this.board = await this.boardService.getBoard(this.boardId);

    this.loadBoards();

    if (typeof this.board === 'undefined') {
      window.location.href = 'https://featurebear.com';
    }

    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
    this.isAdmin = this.user ? this.board.admins.includes(this.user.id) : false;

    this.route.params.subscribe(async (params: Params) => {
      this.boardId = params['id'];

      this.loadPosts();
    });
  }

  async loadBoards() {
    this.project = await this.projectService.getProject(this.board.projectID);

    this.project.settings = this.project.settings || {
      linkBoards: false,
      logo: '',
    };

    for (let boardId of this.project.boards) {
      const projectBoard = await this.boardService.getBoard(boardId);
      if (projectBoard.settings.visibility) {
        this.boards.push(projectBoard);
      }
    }
  }

  openBoard(board: Board) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    let widgetParam = this.widget ? 'true' : '';
    this.router.navigate(['/' + board.id], {
      queryParams: { widget: widgetParam },
    });
    this.showBoardList = false;
  }

  openAddFeature() {
    if (!this.user) {
      this.showLoginModal = true;
      return;
    }
    if (!this.board.settings.visibility) return;

    this.showAddFeature = !this.showAddFeature;
  }

  async setShowAddFeature(state) {
    if (!this.user) {
      this.showLoginModal = true;
      return;
    }

    if (state === false) {
      await this.loadPosts();
      this.showAddFeature = state;
    } else {
      this.showAddFeature = !state;
    }
  }

  async loadPosts() {
    if (!this.board.settings.visibility) {
      return;
    }
    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }

    this.loader = true;
    this.posts = [];

    this.posts = await this.postService.getPostOfBoard(this.boardId);
    this.posts = this.posts.filter((p) => p.status !== 'Done');

    this.sortPosts();
    this.loader = false;
    this.originalPosts = Object.assign([], this.posts);
  }

  sortPosts() {
    this.posts.sort(function (a, b) {
      return b.upvotes.length - a.upvotes.length;
    });
  }

  filterList() {
    if (this.filterText === '') {
      this.posts = this.originalPosts;
      this.sortBy(this.activeSort);
      return;
    }

    this.posts = Object.assign([], this.originalPosts).filter(
      (item) =>
        item.title.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1
    );
  }

  async sortBy(sortString) {
    this.posts = this.originalPosts;

    switch (sortString) {
      case 'Upvotes':
        this.sortPosts();
        break;
      case 'Latest':
        this.posts.sort(function (a, b) {
          const bDate: any = b.date_created as Object;
          const aDate: any = a.date_created as Object;
          return (
            new Date(bDate.seconds * 1000).getTime() -
            new Date(aDate.seconds * 1000).getTime()
          );
        });
        break;
      case 'Done':
        this.posts = await this.postService.getPostByStatusOfBoard(
          this.boardId,
          'Done'
        );
        break;
      default:
        break;
    }
    this.show_list = false;
    this.activeSort = sortString;
  }

  async upvote(post: Post) {
    if (!this.user) {
      this.showLoginModal = true;
      return;
    }

    const hasUserUpvoted = post.upvotes.includes(this.user.id);

    if (hasUserUpvoted) {
      post.upvotes = post.upvotes.filter((userId) => userId !== this.user.id);
      this.user.upvotes = this.user.upvotes.filter(
        (postId) => postId !== post.id
      );
    } else {
      this.http.post(environment.nodeUrl + 'notification/new-upvote', {
        board: this.board,
        post: post,
      });

      post.upvotes.push(this.user.id);
      this.user.upvotes.push(post.id);
    }
    await this.postService.setPost(post);
    await this.userService.setUser(this.user);

    this.sortPosts();
  }

  openPost(postId) {
    let widgetParam = this.widget ? 'true' : '';
    this.router.navigate(['/post/' + postId], {
      queryParams: { widget: widgetParam },
    });
  }

  async closeSettings() {
    this.showBoardSettings = false;
    await this.loadPosts();
  }

  openFeatureBear() {
    window.open('https://featurebear.com', '_blank');
  }

  async closeLoginModal() {
    this.showLoginModal = false;
    this.loginDropdown = false;
    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
  }

  async closeRegisterModal() {
    this.showRegisterModal = false;
    this.loginDropdown = false;
    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
  }

  openChangelog() {
    this.router.navigate(['/' + this.boardId + '/changelog']);
  }

  dropdownHandler() {
    this.showBoardsMenu = !this.showBoardsMenu;
  }

  openSettings() {
    this.router.navigate(['/settings/' + this.board.id]);
  }

  async logout() {
    await this.authService.signOut();
    this.user = null;
    this.loginDropdown = false;
  }

  openTag(tagId) {
    this.router.navigate(['/tag/' + tagId]);
  }

  openRoadmap() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    let widgetParam = this.widget ? 'true' : '';
    this.router.navigate(['/' + this.board.id + '/roadmap'], {
      queryParams: { widget: widgetParam },
    });
  }
}
