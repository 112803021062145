<app-login-dialog
  (closeModal)="closeLoginModal()"
  *ngIf="showLoginModal"
></app-login-dialog>
<nav class="w-full bg-transparent">
  <div class="m-4 h-16 flex justify-between">
    <div class="flex items-center">
      <img
        *ngIf="board?.settings.fbLogoVisibility && project"
        (click)="openFeatureBear()"
        [src]="
          project?.settings?.logo
            ? project?.settings?.logo
            : 'assets/fb-red.png'
        "
        class="cursor-pointer pl-4 w-auto h-12"
      />
    </div>
    <ul class="md:flex hidden items-center h-full">
      <li
        class="cursor-pointer h-full flex items-center text-sm text-gray-800 px-3 tracking-normal relative"
        (click)="dropdownHandler()"
      >
        <ul
          *ngIf="showBoardsMenu"
          class="bg-white shadow rounded py-1 w-32 left-0 mt-16 -ml-4 absolute top-0"
        >
          <li
            (click)="openBoard(board)"
            class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-400 hover:text-white px-3 font-normal"
            *ngFor="let board of boards"
          >
            {{ board.name }}
          </li>
        </ul>
        Boards
        <span class="ml-2 text-gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-chevron-down"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <polyline points="6 9 12 15 18 9" />
          </svg>
        </span>
      </li>
      <li
        (click)="openChangelog()"
        class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
        [ngClass]="{
          'text-fb-red': active.includes('changelog')
        }"
      >
        Changelog
      </li>
      <li
        (click)="openRoadmap()"
        class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
        [ngClass]="{
          'text-fb-red': active.includes('roadmap')
        }"
      >
        Roadmap
      </li>
    </ul>
    <div class="relative">
      <div class="relative flex items-center cursor-pointer">
        <div
          class="flex cursor-pointer items-center"
          (click)="loginDropdown = !loginDropdown"
        >
          <svg
            class="h-6 w-6 m-4 text-gray-600"
            *ngIf="user"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p *ngIf="user" class="text-gray-600 mr-4 text-sm">
            {{ user?.firstname }} {{ user?.surname }}
          </p>
        </div>

        <svg
          *ngIf="!user"
          (click)="loginDropdown = !loginDropdown"
          class="h-6 w-6 m-4 text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
          />
        </svg>
      </div>
      <ul
        *ngIf="loginDropdown"
        class="bg-white shadow rounded mt-2 -ml-24 py-1 w-48 absolute"
      >
        <li
          *ngIf="!user"
          (click)="showLoginModal = true"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Login
        </li>
        <li
          *ngIf="!user"
          (click)="showRegisterModal = true"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Register
        </li>
        <li
          *ngIf="user"
          (click)="openSettings()"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Settings
        </li>
        <li
          *ngIf="user"
          (click)="logout()"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Logout
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="md:hidden flex justify-center mb-10">
  <ul class="flex items-center h-full">
    <li
      class="cursor-pointer h-full flex items-center text-sm text-gray-800 px-3 tracking-normal relative"
      (click)="dropdownHandler()"
    >
      <ul
        *ngIf="showBoardsMenu"
        class="bg-white shadow rounded py-1 w-32 left-0 mt-16 -ml-4 absolute top-0"
      >
        <li
          (click)="openBoard(board)"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-400 hover:text-white px-3 font-normal"
          *ngFor="let board of boards"
        >
          {{ board.name }}
        </li>
      </ul>
      Boards
      <span class="ml-2 text-gray-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-chevron-down"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </span>
    </li>
    <li
      (click)="openChangelog()"
      class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
      [ngClass]="{
        'text-fb-red': active.includes('changelog')
      }"
    >
      Changelog
    </li>
    <li
      (click)="openRoadmap()"
      class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
      [ngClass]="{
        'text-fb-red': active.includes('roadmap')
      }"
    >
      Roadmap
    </li>
  </ul>
</div>
<div class="flex flex-no-wrap">
  <div class="py-8 px-4 md:px-12 w-full">
    <div
      (click)="back()"
      class="cursor-pointer w-20 mb-6 p-1 border rounded-xl flex items-center"
    >
      <svg
        class="cursor-pointer w-4 h-4 mr-2 text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 16l-4-4m0 0l4-4m-4 4h18"
        />
      </svg>
      <p class="cursor-pointer text-gray-700 font-medium text-sm pr-3">back</p>
    </div>

    <div class="flex flex-no-wrap">
      <div class="py-8 px-12 w-full">
        <div
          class="xl:w-5/12 mx-auto sm:mx-0 sm:w-6/12 md:w-6/12 w-10/12 bg-white shadow-lg rounded flex pr-4 absolute left-0 sm:left-auto right-0 sm:top-0 sm:mr-6 mt-16 sm:mt-6 mb-6 sm:mb-0 hidden transition duration-150 ease-in-out"
          id="notification"
        >
          <div
            role="alert"
            aria-label="Close"
            class="cursor-pointer absolute right-0 mr-2 mt-2 text-gray-500 hover:text-gray-600 transition duration-150 ease-in-out"
            (click)="closeModal()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-x"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </div>
          <div
            class="px-5 border-r border-gray-300 flex items-center justify-center text-green-400"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="30"
              height="30"
              fill="currentColor"
            >
              <path
                class="heroicon-ui"
                d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
              ></path>
            </svg>
          </div>
          <div class="flex flex-col justify-center pl-4 py-4">
            <p class="text-sm text-gray-800 font-semibold">Successful Saved</p>
            <p class="text-xs text-gray-600 font-normal">
              You have successfully changed your user settings.
            </p>
          </div>
        </div>
        <div class="flex space-x-2">
          <div class="w-full xl:mx-0 h-12 sm:block bg-white shadow rounded">
            <ul class="flex">
              <li
                (click)="this.active_status = 1"
                [ngClass]="
                  active_status == 1
                    ? 'text-sm border-fb-red pt-3 rounded-t text-gray-600 ml-2 mr-12'
                    : 'text-sm text-gray-600 py-3 flex items-center ml-2 mr-12  cursor-pointer'
                "
              >
                <div class="flex items-center mb-3">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-home"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                  </span>
                  <span class="ml-1 font-normal">User</span>
                </div>
                <div
                  *ngIf="active_status == 1"
                  class="w-full h-1 bg-fb-red rounded-t-md"
                ></div>
              </li>
              <li
                (click)="this.active_status = 2"
                [ngClass]="
                  active_status == 2
                    ? 'text-sm border-fb-red pt-3 rounded-t text-gray-700 mr-12'
                    : 'text-sm text-gray-600 py-3 flex items-center mr-12  cursor-pointer'
                "
              >
                <div class="flex items-center mb-3">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-eye"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      />
                    </svg>
                  </span>
                  <span class="ml-1 font-normal">Notification</span>
                </div>
                <div
                  *ngIf="active_status == 2"
                  class="w-full h-1 bg-fb-red rounded-t-md"
                ></div>
              </li>
            </ul>
          </div>
        </div>
        <form id="form" *ngIf="active_status === 1">
          <div class="container mx-auto shadow bg-white rounded">
            <div class="xl:w-full border-b border-gray-300 py-5">
              <div class="flex items-center w-11/12 mx-auto">
                <p class="text-lg text-gray-800 font-bold">User Settings</p>
              </div>
            </div>
            <div class="w-11/12 mx-auto" *ngIf="user">
              <div class="xl:w-9/12 mx-auto xl:mx-0">
                <div class="mt-16 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
                  <label
                    for="firstname"
                    class="pb-2 text-sm font-bold text-gray-800"
                    >Firstname</label
                  >
                  <input
                    type="text"
                    id="firstname"
                    [(ngModel)]="user.firstname"
                    name="firstname"
                    class="border border-gray-300 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 text-gray-800"
                    required
                  />
                </div>
                <div class="mt-8 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
                  <label
                    for="surname"
                    class="pb-2 text-sm font-bold text-gray-800"
                    >Surname</label
                  >
                  <input
                    type="text"
                    id="surname"
                    [(ngModel)]="user.surname"
                    name="surname"
                    class="border border-gray-300 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 text-gray-800"
                    required
                  />
                </div>
                <div class="mt-8 flex flex-col xl:w-2/6 lg:w-2/6 w-full">
                  <label
                    for="email"
                    class="pb-2 text-sm font-bold text-gray-800"
                    >Email</label
                  >
                  <input
                    type="email"
                    disabled
                    [ngModel]="user?.email"
                    id="email"
                    name="email"
                    class="border border-gray-300 pl-3 py-3 shadow-sm rounded text-sm focus:outline-none focus:border-indigo-700 text-gray-800"
                    required
                  />
                </div>
              </div>
            </div>
            <div
              class="w-full py-4 sm:px-12 mt-6 flex justify-between rounded-bl rounded-br"
            >
              <button
                class="my-2 -ml-2 text-white w-24 flex items-center bg-red-400 transition duration-150 ease-in-out hover:bg-gray-200 border border-gray-300 p-4 rounded py-2 text-sm cursor-pointer"
                (click)="save()"
              >
                Save
              </button>
              <ul
                class="flex flex-col md:flex-row md:items-center text-gray-600 text-sm"
              >
                <li class="flex items-center md:mt-0">
                  <a
                    *ngIf="!showDeleteUser"
                    (click)="showDeleteUser = true"
                    class="my-2 text-white flex items-center bg-red-400 transition duration-150 ease-in-out hover:bg-gray-200 border border-gray-300 mx-2 p-4 rounded py-2 text-sm cursor-pointer"
                  >
                    <span class="h-4 w-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </span>
                    Delete Account
                  </a>
                </li>
              </ul>
            </div>
            <div
              *ngIf="showDeleteUser"
              class="mb-4 my-6 shadow sm:py-6 py-4 px-10 bg-white rounded-md"
            >
              <div class="flex pb-3 items-center">
                <div class="-ml-1 text-gray-600">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-trash"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <line x1="4" y1="7" x2="20" y2="7" />
                    <line x1="10" y1="11" x2="10" y2="17" />
                    <line x1="14" y1="11" x2="14" y2="17" />
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                  </svg>
                </div>
                <p class="text-lg text-gray-800 font-semibold pl-2">
                  Delete your Account?
                </p>
              </div>
              <p class="text-sm text-gray-600 pb-3 font-normal">
                Do you really want to delete your account and all your
                boards/requests??
              </p>
              <button
                (click)="deleteUser()"
                class="focus:outline-none bg-red-400 transition duration-150 ease-in-out rounded text-white px-3 py-2 text-xs"
              >
                Delete
              </button>
            </div>
          </div>
        </form>

        <div *ngIf="active_status === 2">
          <div class="container mx-auto bg-white shadow rounded">
            <div class="xl:w-full border-b border-gray-300 py-5">
              <div class="flex items-center w-11/12 mx-auto">
                <div class="container mx-auto">
                  <div class="mx-auto xl:w-full">
                    <p class="text-lg text-gray-800 font-bold">Notification</p>
                    <p class="text-sm text-gray-500 pt-1">
                      Set your notification settings
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="container mx-auto bg-white dark:bg-gray-800 py-6 w-11/12"
            >
              <div
                class="flex items-center mb-8 text-gray-800 dark:text-gray-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-bell"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <path
                    d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"
                  />
                  <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
                </svg>
                <p
                  class="text-sm font-bold ml-2 text-gray-800 dark:text-gray-100"
                >
                  Notifications
                </p>
              </div>

              <div class="flex justify-between items-center mb-8">
                <div class="w-9/12">
                  <p class="text-sm text-gray-800 dark:text-gray-100 pb-1">
                    New comment
                  </p>
                  <p class="text-sm text-gray-500 dark:text-gray-400">
                    Get notified when someone post a new comment on post you
                    upvoted or commented
                  </p>
                </div>
                <div class="cursor-pointer rounded-full relative shadow-sm">
                  <input
                    type="checkbox"
                    [(ngModel)]="user.notification.newComment"
                    (change)="save()"
                    id="notificationNewComment"
                    class="focus:outline-none checkbox w-4 h-4 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer"
                  />
                  <label
                    for="notificationNewComment"
                    class="toggle-label dark:bg-gray-700 block w-12 h-6 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
                  ></label>
                </div>
              </div>
              <div class="flex justify-between items-center mb-8">
                <div class="w-9/12">
                  <p class="text-sm text-gray-800 dark:text-gray-100 pb-1">
                    New status of a post
                  </p>
                  <p class="text-sm text-gray-500 dark:text-gray-400">
                    Get notified when the status of a post you upvoted or
                    commented gets updated
                  </p>
                </div>
                <div class="cursor-pointer rounded-full relative shadow-sm">
                  <input
                    type="checkbox"
                    [(ngModel)]="user.notification.newStatus"
                    (change)="save()"
                    id="notificationNewStatus"
                    class="focus:outline-none checkbox w-4 h-4 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer"
                  />
                  <label
                    for="notificationNewStatus"
                    class="toggle-label dark:bg-gray-700 block w-12 h-6 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
                  ></label>
                </div>
              </div>
              <div class="flex justify-between items-center">
                <div class="w-9/12">
                  <p class="text-sm text-gray-800 dark:text-gray-100 pb-1">
                    New Tag
                  </p>
                  <p class="text-sm text-gray-500 dark:text-gray-400">
                    Get notifitied when there is a new tag on a post
                  </p>
                </div>
                <div class="cursor-pointer rounded-full relative shadow-sm">
                  <input
                    type="checkbox"
                    [(ngModel)]="user.notification.newTag"
                    (change)="save()"
                    id="notificationNewTag"
                    class="focus:outline-none checkbox w-4 h-4 rounded-full bg-white absolute m-1 shadow-sm appearance-none cursor-pointer"
                  />
                  <label
                    for="notificationNewTag"
                    class="toggle-label dark:bg-gray-700 block w-12 h-6 overflow-hidden rounded-full bg-gray-300 cursor-pointer"
                  ></label>
                </div>
              </div>
            </div>
            <style>
              .checkbox:checked {
                /* Apply class right-0*/
                right: 0;
              }
              .checkbox:checked + .toggle-label {
                /* Apply class bg-indigo-700 */
                background-color: #ff7070;
              }
            </style>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
