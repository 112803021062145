import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Board } from 'src/app/models/board.model';
import { User, Plan } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BoardService } from 'src/app/services/board.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register-dialog',
  templateUrl: './register-dialog.component.html',
  styleUrls: ['./register-dialog.component.css'],
})
export class RegisterDialogComponent implements OnInit {
  @Input() board: Board;
  @Output() closeModal = new EventEmitter();
  @Output() openSignInModal = new EventEmitter();

  registerForm: FormGroup;

  freePlan: Plan = {
    name: 'free',
    period: 'monthly',
    price: 0,
  };

  showError: boolean = false;
  constructor(
    private fb: FormBuilder,
    private boardService: BoardService,
    private projectService: ProjectService,
    private userService: UserService,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.createForm();
  }
  createForm() {
    this.registerForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.minLength(8)],
      },
      { updateOn: 'submit' }
    );
  }

  ngOnInit(): void {}

  register() {
    if (!this.registerForm.valid) {
      this.showError = true;
      return;
    }

    const credentials = {
      email: this.registerForm.controls['email'].value,
      password: this.registerForm.controls['password'].value,
    };

    this.authService
      .signUp(credentials)
      .then(async (u) => {
        const registeredUser: User = {
          email: u.user.email,
          id: u.user.uid,
          boards: [],
          comments: [],
          firstname: '',
          surname: '',
          projects: [],
          upvotes: [],
          createdPosts: [],
          registeredDate: new Date(),
          plan: this.freePlan,
          firstLogin: true,
          notification: {
            newComment: true,
            newStatus: true,
            newTag: true,
          },
        };

        const isUserRegistered = await this.userService.getUserById(u.user.uid);
        if (isUserRegistered) {
          await this.userService.setUser(registeredUser);
        } else {
          await this.userService.setUser(registeredUser);
        }

        const user: User = await this.userService.getUserById(u.user.uid);

        const isUserRegisteredInBoard = user.boards.includes(this.board.id);
        if (!isUserRegisteredInBoard) {
          this.http.post(
            environment.nodeUrl + 'notification/new-registered-user',
            {
              board: this.board,
            }
          );

          user.boards.push(this.board.id);
          this.board.registeredUsers.push(user.id);
          await this.userService.setUser(user);
          await this.boardService.setBoard(this.board);
        }

        this.close();
      })
      .catch(() => {
        this.showError = true;
      });
  }

  signInWithGoogle() {
    this.authService
      .signInWithGoogle()
      .then(async (u) => {
        const isUserRegistered = await this.userService.getUserById(u.user.uid);
        if (!isUserRegistered) {
          const name = u.user.displayName.split(' ');
          const registeredUser: User = {
            email: u.user.email,
            id: u.user.uid,
            boards: [],
            comments: [],
            firstname: name[0],
            surname: name[1],
            projects: [],
            upvotes: [],
            createdPosts: [],
            registeredDate: new Date(),
            plan: this.freePlan,
            firstLogin: true,
            notification: {
              newComment: true,
              newStatus: true,
              newTag: true,
            },
          };
          await this.userService.setUser(registeredUser);
        }

        if (typeof this.board !== 'undefined') {
          let user: User = await this.userService.getUser();
          const isUserRegisteredInBoard = user.boards.includes(this.board.id);
          if (!isUserRegisteredInBoard) {
            this.http.post(
              environment.nodeUrl + 'notification/new-registered-user',
              {
                board: this.board,
              }
            );
            user.boards.push(this.board.id);
            this.board.registeredUsers.push(user.id);
            await this.userService.setUser(user);
            await this.boardService.setBoard(this.board);
          }
        }

        this.close();
      })
      .catch((err) => {
        this.showError = true;
      });
  }

  signInWithGithub() {
    this.authService
      .signInWithGithub()
      .then(async (u) => {
        const isUserRegistered = await this.userService.getUserById(u.user.uid);
        if (!isUserRegistered) {
          const name = u.user.displayName.split(' ');
          const registeredUser: User = {
            email: u.user.email,
            id: u.user.uid,
            boards: [],
            comments: [],
            firstname: name[0],
            surname: name[1],
            projects: [],
            upvotes: [],
            createdPosts: [],
            registeredDate: new Date(),
            plan: this.freePlan,
            firstLogin: true,
            notification: {
              newComment: true,
              newStatus: true,
              newTag: true,
            },
          };
          await this.userService.setUser(registeredUser);
        }

        if (typeof this.board !== 'undefined') {
          let user: User = await this.userService.getUser();
          const isUserRegisteredInBoard = user.boards.includes(this.board.id);
          if (!isUserRegisteredInBoard) {
            this.http.post(
              environment.nodeUrl + 'notification/new-registered-user',
              {
                board: this.board,
              }
            );
            user.boards.push(this.board.id);
            this.board.registeredUsers.push(user.id);
            await this.userService.setUser(user);
            await this.boardService.setBoard(this.board);
          }
        }

        this.close();
      })
      .catch((err) => {
        this.showError = true;
      });
  }

  close() {
    this.closeModal.emit(true);
  }

  openLogin() {
    this.openSignInModal.emit(true);
  }
}
