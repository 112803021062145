import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.css'],
})
export class ProjectSettingsComponent implements OnInit {
  @Input() project: Project;
  @Output() closeSettingsModal = new EventEmitter();
  projectForm: FormGroup;
  showDeleteBoard: boolean = false;
  constructor(
    private fb: FormBuilder,
    private boardService: BoardService,
    private projectService: ProjectService,
    private userService: UserService,
    private postService: PostService,
    private router: Router
  ) {}
  createForm() {
    this.projectForm = this.fb.group(
      {
        name: [
          this.project.name,
          [Validators.required, Validators.minLength(3)],
        ],
        description: [this.project.description],
      },
      { updateOn: 'change' }
    );
  }

  ngOnInit() {
    this.createForm();
  }

  async save() {
    if (!this.projectForm.valid) return;

    this.project.name = this.projectForm.controls['name'].value;
    this.project.description = this.projectForm.controls['description'].value;

    await this.projectService.setProject(this.project);
    this.close();
  }

  async deleteProject() {
    for (let boardId of this.project.boards) {
      const board = await this.boardService.getBoard(boardId);

      let user: User = await this.userService.getUser();
      const userIndex = user.boards.findIndex((b) => b === boardId);
      user.boards.splice(userIndex, 1);

      const userProjectIndex = user.projects.findIndex(
        (b) => b === this.project.id
      );
      user.projects.splice(userProjectIndex, 1);

      for (let postId of board.posts) {
        const post = await this.postService.getPost(postId);
        let creator: User = await this.userService.getUserById(post.creator);
        const creatorIndex = creator.createdPosts.findIndex(
          (b) => b === postId
        );
        creator.createdPosts.splice(creatorIndex, 1);

        await this.userService.setUser(creator);
        await this.postService.deletePost(postId);

        for (let upvote of user.upvotes) {
          const upvoteIndex = user.upvotes.findIndex((u) => u === upvote);
          user.upvotes.splice(upvoteIndex, 1);
        }
      }

      await this.userService.setUser(user);
      await this.boardService.deleteBoard(board.id);
    }

    await this.projectService.deleteProject(this.project.id);

    this.router.navigate(['/project/']);
  }

  close() {
    this.closeSettingsModal.emit(true);
  }
}
