import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  showError: boolean = false;
  showForgotPassword: boolean = false;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    this.createForm();
  }
  createForm() {
    this.loginForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]],
      },
      { updateOn: 'submit' }
    );
  }

  ngOnInit(): void {}

  login() {

    if (!this.loginForm.valid) {
      this.showError = true;
      return;
    }
    console.log(this.loginForm);


    const credentials = {
      email: this.loginForm.controls['email'].value,
      password: this.loginForm.controls['password'].value,
    };
    this.authService
      .signInWithEmail(credentials)
      .then(() => {
        this.router.navigate(['/project']);
      })
      .catch((err) => {
        this.showError = true;
      });
  }

  openRegister(){
    this.router.navigate(['/register'])
  }
}
