import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { AuthService } from 'src/app/services/auth.service';
import { BoardService } from 'src/app/services/board.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  active: string = '';
  boardId: string = '';
  @Input() boards: Board[];

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.active = router.url;
    this.boardId = this.route.snapshot.paramMap.get('id');
  }

  public openSidebar = (flag) => {
    let sidebar = document.getElementById('sidebar');
    flag ? sidebar.classList.add('hidden') : sidebar.classList.remove('hidden'),
      sidebar.classList.remove('invisible');
  };

  async ngOnInit() {}

  openBoard(boardId) {
    this.router.navigate(['/board/' + boardId]);
  }

  async logout() {
    await this.authService.signOut();
    this.router.navigate(['/login']);
  }

  openUserSettings() {
    this, this.router.navigate(['/user-settings']);
  }
}
