<!-- Navbar Ends -->
<div
  (click)="openSidebar(false)"
  class="flex items-center justify-center rounded-r-md bg-gray-800 text-gray-300 ml-0 cursor-pointer absolute inset-0 mt-10 m-auto w-8 h-8"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="icon icon-tabler icon-tabler-chevron-right"
    width="28"
    height="28"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    fill="none"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <polyline points="9 6 15 12 9 18" />
  </svg>
</div>
<div
  id="sidebar"
  class="overflow-y-scroll invisible lg:visible lg:overflow-y-auto fixed lg:sticky h-screen lg:h-screen z-40 top-0 bg-gray-900 pt-10 w-64"
>
  <div class="px-8">
    <div class="flex items-center justify-between cursor-pointer">
      <div class="w-32" [routerLink]="['/project']">
        <img
          class="w-full"
          src="assets/fb-white-small.png"
          alt="quicklist logo"
        />
      </div>
      <div
        (click)="openSidebar(true)"
        class="text-gray-700 ml-8 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-chevron-left"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <polyline points="15 6 9 12 15 18" />
        </svg>
      </div>
    </div>
    <ul class="my-10 flex flex-wrap">
      <li class="w-1/2 flex justify-start">
        <a
          [routerLink]="['/project']"
          [ngClass]="
            active == '/project'
              ? 'bg-gray-700 rounded-md text-gray-300 flex flex-col justify-center items-center w-20 h-20 p-4'
              : 'bg-transparent rounded-md text-gray-600 flex flex-col justify-center items-center w-20 h-20 p-4'
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-stack"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <polyline points="12 4 4 8 12 12 20 8 12 4" />
            <polyline points="4 12 12 16 20 12" />
            <polyline points="4 16 12 20 20 16" />
          </svg>
          <p class="mt-1 uppercase font-semibold text-xs">Projects</p>
        </a>
      </li>
    </ul>
    <div *ngIf="boards" class="flex items-center justify-between text-gray-600">
      <h4 class="uppercase font-semibold">Boards</h4>
    </div>
    <ul class="text-gray-600 mt-8">
      <li
        *ngFor="let board of boards"
        class="mb-5 cursor-pointer"
        (click)="openBoard(board.id)"
      >
        {{ board.name }}
      </li>

      <li *ngIf="boards?.length === 0" class="mb-5 cursor-pointer">
        No Boards created yet
      </li>
    </ul>
  </div>
  <div
    (click)="openUserSettings()"
    class="px-8 py-4 w-full flex border-t border-gray-800 mt-20 items-center text-gray-600 uppercase text-xs"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="cursor-pointer icon icon-tabler icon-tabler-trash"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
    <p class="cursor-pointer pl-2">Settings</p>
  </div>
  <div
    (click)="logout()"
    class="px-8 py-4 w-full flex items-center text-gray-600 uppercase text-xs"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="cursor-pointer icon icon-tabler icon-tabler-trash"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
      />
    </svg>
    <p class="cursor-pointer pl-2">Logout</p>
  </div>
</div>
<!-- Navbar Ends -->
