<app-forgot-password
  *ngIf="showForgotPassword"
  (closeModal)="showForgotPassword = false"
></app-forgot-password>
<section class="bg-white">
  <div class="mx-auto flex h-full justify-center flex-col lg:flex-row">
    <form id="login" class="w-full lg:w-1/2 flex justify-center">
      <div
        class="w-full sm:w-4/6 md:w-4/6 xl:w-2/3 text-gray-800 mb-12 sm:mb-0 px-2 sm:px-0"
      >
        <div
          class="mb-10 md:mb-18 md:py-10 px-2 flex flex-col items-center justify-center mt-6 sm:mt-0"
        >
          <img src="assets/fb.png" class="w-auto h-32" />
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
          <div class="px-2 flex flex-col items-center justify-center">
            <h3
              class="text-2xl md:text-3xl sm:text-4xl font-bold leading-tight"
            >
              Login To Your Account
            </h3>
          </div>
          <div class="mt-12 w-full px-2 sm:px-6">
            <div class="flex flex-col mt-5">
              <label for="email" class="text-lg font-semibold leading-tight"
                >Email</label
              >
              <input
                [ngClass]="{
                  'border-red-400':
                    loginForm.controls['email'].invalid &&
                    (loginForm.controls['email'].dirty ||
                      loginForm.controls['email'].touched)
                }"
                formControlName="email"
                required
                id="email"
                name="email"
                class="h-10 px-2 w-full rounded mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 border-gray-300 border shadow"
                type="email"
              />
            </div>
            <div class="flex flex-col mt-5">
              <label for="password" class="text-lg font-semibold fleading-tight"
                >Password</label
              >
              <input
                formControlName="password"
                required
                id="password"
                name="password"
                class="h-10 px-2 w-full rounded mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 border-gray-300 border shadow"
                type="password"
              />
            </div>
          </div>

          <div class="pt-6 w-full flex justify-between px-2 sm:px-6">
            <div
              class="text-xs text-gray-600 cursor-pointer"
              (click)="showForgotPassword = true"
            >
              Forgot Password?
            </div>
          </div>
          <div
            *ngIf="showError"
            class="bg-white ml-6 p-4 mt-6 px-2 sm:px-6 w-full xl:flex lg:flex xl:items-center"
          >
            <div class="mr-2 mb-1 xl:mb-0 text-red-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
                fill="currentColor"
              >
                <path
                  class="heroicon-ui"
                  d="M4.93 19.07A10 10 0 1 1 19.07 4.93 10 10 0 0 1 4.93 19.07zm1.41-1.41A8 8 0 1 0 17.66 6.34 8 8 0 0 0 6.34 17.66zM13.41 12l1.42 1.41a1 1 0 1 1-1.42 1.42L12 13.4l-1.41 1.42a1 1 0 1 1-1.42-1.42L10.6 12l-1.42-1.41a1 1 0 1 1 1.42-1.42L12 10.6l1.41-1.42a1 1 0 1 1 1.42 1.42L13.4 12z"
                />
              </svg>
            </div>
            <div
              class="h-1 w-1 bg-gray-300 rounded-full mr-2 hidden xl:block"
            ></div>
            <p class="text-sm sm:text-base text-gray-600 pb-2 sm:pb-0">
              Ihre Zugangsdaten stimmen nicht überein
            </p>
          </div>
          <div class="px-2 sm:mb-16 sm:px-6">
            <button
              type="submit"
              class="focus:outline-none w-full sm:w-auto bg-fb-red transition duration-150 ease-in-out rounded text-white px-8 py-3 text-lg mt-6"
            >
              Login to Your Account
            </button>
            <p class="mt-8 text-md text-center">
              Don’t Have An Account?
              <a
                (click)="openRegister()"
                class="ml-4 cursor-pointer px-2 py-1 rounded text-fb-red"
                >Try For Free</a
              >
            </p>
          </div>
        </form>
      </div>
    </form>
    <div
      class="w-full lg:w-1/2 bg-fb-red px-2 py-12 sm:p-12 flex flex-col relative"
    >
      <div class="flex-1 flex flex-col justify-center">
        <div>
          <img src="https://dh-ui.s3.amazonaws.com/assets/team.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>
