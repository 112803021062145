import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BoardComponent } from './pages/board/board.component';

import { PostDetailComponent } from './pages/post-detail/post-detail.component';

import { AuthGuard } from './guards/auth.guard';
import { RegisterComponent } from './pages/register/register.component';
import { UserSettingsComponent } from './pages/user-settings/user-settings.component';
import { TagComponent } from './pages/tag/tag.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { RoadmapComponent } from './pages/roadmap/roadmap.component';

const routes: Routes = [
  { path: ':id', component: BoardComponent },
  { path: '', component: BoardComponent },
  {
    path: 'settings/:id',
    component: UserSettingsComponent,
    canActivate: [AuthGuard],
  },
  { path: 'register', component: RegisterComponent },
  { path: 'post/:id', component: PostDetailComponent },
  { path: 'tag/:id', component: TagComponent },
  { path: ':id/changelog', component: ChangelogComponent },
  { path: ':id/roadmap', component: RoadmapComponent },
  { path: ':id/changelog/:changelogId', component: ChangelogComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
