<app-login-dialog
  *ngIf="showLoginModal"
  (openSignUpModal)="showRegisterModal = true; showLoginModal = false"
  (closeModal)="closeLoginModal()"
  (openResetPasswordModal)="showForgotPassword = true; showLoginModal = false"
  [board]="board"
></app-login-dialog>

<app-register-dialog
  *ngIf="showRegisterModal"
  (openSignInModal)="showLoginModal = true; showRegisterModal = false"
  (closeModal)="closeRegisterModal()"
  [board]="board"
></app-register-dialog>

<app-forgot-password
  *ngIf="showForgotPassword"
  (closeModal)="showForgotPassword = false"
></app-forgot-password>

<!-- copy url -->
<div
  class="hidden xl:w-5/12 mx-auto sm:mx-0 sm:w-6/12 md:w-6/12 w-10/12 bg-white dark:bg-gray-800 shadow-lg rounded flex pr-4 absolute left-0 sm:left-auto right-0 sm:top-0 sm:mr-6 mt-24 sm:mt-6 mb-6 sm:mb-0 transition duration-150 ease-in-out"
  id="notification"
>
  <div
    class="px-5 border-r border-gray-300 dark:border-gray-700 flex items-center justify-center text-green-400"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="30"
      height="30"
      fill="currentColor"
    >
      <path
        class="heroicon-ui"
        d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"
      ></path>
    </svg>
  </div>
  <div class="flex flex-col justify-center pl-4 py-4">
    <p class="text-sm text-gray-800 dark:text-gray-100 font-semibold">
      URL copied!
    </p>
  </div>
</div>
<!-- copy url  end -->

<!-- Not Live Start -->
<div *ngIf="board">
  <div
    role="alert"
    *ngIf="!this.board.settings.visibility"
    class="z-50 xl:w-5/12 mx-auto sm:mx-0 sm:w-6/12 md:w-3/5 w-11/12 bg-white shadow-lg rounded flex sm:flex-row flex-col pr-4 absolute left-0 sm:left-auto right-0 sm:top-0 sm:mr-6 mt-16 sm:mt-6 mb-6 sm:mb-0 transition duration-150 ease-in-out"
    id="notification"
  >
    <div
      class="sm:px-6 px-4 flex items-center sm:justify-center sm:border-r border-gray-300 rounded-tl rounded-bl mt-4 sm:mt-0 text-yellow-400"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="40"
        height="40"
        fill="currentColor"
      >
        <path
          class="heroicon-ui"
          d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
        />
      </svg>
    </div>
    <div class="flex flex-col justify-center pl-4 sm:w-9/12 sm:pt-6 pb-6 pt-2">
      <p class="text-lg text-gray-800 font-semibold pb-1">Attention Needed</p>
      <p class="text-sm text-gray-600 font-normal">
        Your Board is not live yet. Go to the Board-settings and toggle the
        access button!
      </p>
    </div>
  </div>
</div>
<!-- Not Live End -->

<nav class="w-full bg-transparent">
  <div class="m-4 h-16 flex justify-between">
    <div class="flex items-center">
      <img
        *ngIf="board?.settings.fbLogoVisibility && project"
        (click)="openFeatureBear()"
        [src]="
          project?.settings?.logo
            ? project?.settings?.logo
            : 'assets/fb-red.png'
        "
        class="cursor-pointer pl-4 w-auto h-12"
      />
    </div>
    <ul class="md:flex hidden items-center h-full">
      <li
        class="cursor-pointer h-full flex items-center text-sm text-gray-800 px-3 tracking-normal relative"
        (click)="dropdownHandler()"
      >
        <ul
          *ngIf="showBoardsMenu"
          class="bg-white shadow rounded py-1 w-32 left-0 mt-16 -ml-4 absolute top-0"
        >
          <li
            (click)="openBoard(board)"
            class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-400 hover:text-white px-3 font-normal"
            *ngFor="let board of boards"
          >
            {{ board.name }}
          </li>
        </ul>
        Boards
        <span class="ml-2 text-gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-chevron-down"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <polyline points="6 9 12 15 18 9" />
          </svg>
        </span>
      </li>
      <li
        (click)="openChangelog()"
        class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
        [ngClass]="{
          'text-fb-red': active.includes('changelog')
        }"
      >
        Changelog
      </li>
      <li
        (click)="openRoadmap()"
        class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
        [ngClass]="{
          'text-fb-red': active.includes('roadmap')
        }"
      >
        Roadmap
      </li>
    </ul>
    <div class="relative">
      <div class="relative flex items-center cursor-pointer">
        <div
          class="flex cursor-pointer items-center"
          (click)="loginDropdown = !loginDropdown"
        >
          <svg
            class="h-6 w-6 m-4 text-gray-600"
            *ngIf="user"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p *ngIf="user" class="text-gray-600 mr-4 text-sm">
            {{ user?.firstname }} {{ user?.surname }}
          </p>
        </div>

        <svg
          *ngIf="!user"
          (click)="loginDropdown = !loginDropdown"
          class="h-6 w-6 m-4 text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
          />
        </svg>
      </div>
      <ul
        *ngIf="loginDropdown"
        class="bg-white shadow rounded mt-2 -ml-24 py-1 w-48 absolute"
      >
        <li
          *ngIf="!user"
          (click)="showLoginModal = true"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Login
        </li>
        <li
          *ngIf="!user"
          (click)="showRegisterModal = true"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Register
        </li>
        <li
          *ngIf="user"
          (click)="openSettings()"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Settings
        </li>
        <li
          *ngIf="user"
          (click)="logout()"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Logout
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="md:hidden flex justify-center mb-10">
  <ul class="flex items-center h-full">
    <li
      class="cursor-pointer h-full flex items-center text-sm text-gray-800 px-3 tracking-normal relative"
      (click)="dropdownHandler()"
    >
      <ul
        *ngIf="showBoardsMenu"
        class="bg-white shadow rounded py-1 w-32 left-0 mt-16 -ml-4 absolute top-0"
      >
        <li
          (click)="openBoard(board)"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-400 hover:text-white px-3 font-normal"
          *ngFor="let board of boards"
        >
          {{ board.name }}
        </li>
      </ul>
      Boards
      <span class="ml-2 text-gray-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-chevron-down"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </span>
    </li>
    <li
      (click)="openChangelog()"
      class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
      [ngClass]="{
        'text-fb-red': active.includes('changelog')
      }"
    >
      Changelog
    </li>
    <li
      (click)="openRoadmap()"
      class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
      [ngClass]="{
        'text-fb-red': active.includes('roadmap')
      }"
    >
      Roadmap
    </li>
  </ul>
</div>
<div class="w-full text-center justify-center">
  <div
    class="text-center mb-3 xl:mb-0 flex flex-col items-center justify-between"
  >
    <h2 class="text-5xl text-gray-900 font-bold">Roadmap</h2>
  </div>
</div>

<div class="py-8 px-10 md:px-12 w-full">
  <div class="flex flex-col justify-center">
    <div
      id="chart"
      style="text-align: -webkit-center"
      *ngIf="chartOptions && roadmapItems.length > 0"
    >
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [fill]="chartOptions.fill"
        [grid]="chartOptions.grid"
        [dataLabels]="chartOptions.dataLabels"
        [plotOptions]="chartOptions.plotOptions"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
      ></apx-chart>

      <div
        class="lg:w-4/5 w-full flex flex-col justify-start py-3"
        *ngFor="let roadmap of roadmapItems; let i = index"
      >
        <div class="mt-16 flex items-start justify-start text-gray-700">
          <div class="flex justify-start space-x-3 items-end">
            <h3
              class="cursor-pointer text-3xl text-gray-900 dark:text-gray-100 font-semibold"
            >
              {{ roadmap.name }}
            </h3>
            <p class="cursor-pointer text-lg text-gray-500 dark:text-gray-100">
              ({{ roadmap.startDate.toDate() | date }} -
              {{ roadmap.endDate.toDate() | date }})
            </p>
          </div>
        </div>

        <hr class="mb-8 mt-6 border-t border-gray-300 dark:border-gray-700" />
        <div class="flex flex-col items-start justify-start">
          <p class="text-sm text-gray-400" *ngIf="roadmap.description">
            Description
          </p>
          <p class="mb-8 text-sm" *ngIf="roadmap.description">
            {{ roadmap.description }}
          </p>
          <p
            class="text-sm mb-5 text-gray-400"
            *ngIf="roadmap.featureRequests.length > 0"
          >
            Linked Posts
          </p>
          <ul class="mx-2" *ngFor="let post of roadmap.featureRequests">
            <li
              (click)="openPost(post)"
              class="flex justify-start items-center mb-6"
            >
              <svg
                class="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
              <p
                class="cursor-pointer text-sm text-gray-800 dark:text-gray-100"
              >
                {{ post | post | async }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
