import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { BoardService } from 'src/app/services/board.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-board-list',
  templateUrl: './board-list.component.html',
})
export class BoardListComponent implements OnInit {
  showAddBoardModal: boolean = false;
  projectId: string;
  project: Project;
  boards: Board[] = [];
  showProjectSettings: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private userService: UserService,
    private boardService: BoardService
  ) {}

  async ngOnInit() {
    this.projectId = this.route.snapshot.paramMap.get('id');
    const user: User = await this.userService.getUser();

    if (user.projects.indexOf(this.projectId) < 0) {
      this.router.navigate(['/project']);
    }

    this.loadBoards();
  }

  async loadBoards() {
    this.boards = [];
    this.project = await this.projectService.getProject(this.projectId);
    this.project.boards.forEach(async (boardId) => {
      this.boards.push(await this.boardService.getBoard(boardId));
    });
    this.boardService.setBoards(this.boards);
  }

  addBoard() {
    this.showAddBoardModal = true;
  }

  openBoard(boardId) {
    this.router.navigate(['/board/' + boardId]);
  }

  back() {
    this.router.navigate(['/project']);
  }

  closeModal() {
    this.showAddBoardModal = false;
    this.loadBoards();
  }

  closeSettingsModal(){
    this.showProjectSettings = false
  }
}
