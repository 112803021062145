import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ProjectComponent } from './pages/project/project.component';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AddProjectComponent } from './components/add-project/add-project.component';
import { AddPostComponent } from './components/add-post/add-post.component';
import { BoardComponent } from './pages/board/board.component';
import { BoardListComponent } from './pages/board-list/board-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { TagInputModule } from 'ngx-chips';
import { PostDetailComponent } from './pages/post-detail/post-detail.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { ProjectService } from './services/project.service';
import { BoardService } from './services/board.service';
import { PostService } from './services/post.service';
import { CommentService } from './services/comment.service';
import { TagService } from './services/tag.service';
import { ChangelogService } from './services/changlog.service';
import { RoadmapService } from './services/roadmap.service';
import { RegisterComponent } from './pages/register/register.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { UserPipe } from './pipes/user.pipe';
import { BoardSettingsComponent } from './components/board-settings/board-settings.component';
import { UserSettingsComponent } from './pages/user-settings/user-settings.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ProjectSettingsComponent } from './components/project-settings/project-settings.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { RegisterDialogComponent } from './components/register-dialog/register-dialog.component';
import { TagPipe } from './pipes/tag.pipe';
import { TagColorPipe } from './pipes/tag-color.pipe';
import { TagComponent } from './pages/tag/tag.component';
import { HttpClientModule } from '@angular/common/http';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { SafePipe } from './pipes/safe.pipe';
import { ChangelogPipe } from './pipes/changelog.pipe';
import { NgApexchartsModule } from 'ng-apexcharts';
import { RoadmapComponent } from './pages/roadmap/roadmap.component';
import { PostPipe } from './pipes/post.pipe';

registerLocaleData(localeDe);

const config = {
  apiKey: 'AIzaSyC6xpo7PC8K0O4_ht5RdYZIMVEKtmN16Bg',
  authDomain: 'votingapp-426cb.firebaseapp.com',
  projectId: 'votingapp-426cb',
  storageBucket: 'votingapp-426cb.appspot.com',
  messagingSenderId: '556939607054',
  appId: '1:556939607054:web:41ea24626488ea4828105f',
  /*   apiKey: 'AIzaSyCg_P2vFgO5Vj5BP4_Unpon5FY3tbSfUdo',
  authDomain: 'featurebeartest.firebaseapp.com',
  projectId: 'featurebeartest',
  storageBucket: 'featurebeartest.appspot.com',
  messagingSenderId: '367044390872',
  appId: '1:367044390872:web:c26e6d6fefeb053e168cad', */
};
@NgModule({
  declarations: [
    AppComponent,
    ProjectComponent,
    BoardComponent,
    BoardListComponent,
    SidebarComponent,
    AddProjectComponent,
    AddPostComponent,
    LoginComponent,
    PostDetailComponent,
    RegisterComponent,
    UserPipe,
    BoardSettingsComponent,
    UserSettingsComponent,
    ForgotPasswordComponent,
    ProjectSettingsComponent,
    LoginDialogComponent,
    RegisterDialogComponent,
    TagPipe,
    TagColorPipe,
    TagComponent,
    ChangelogComponent,
    SafePipe,
    ChangelogPipe,
    RoadmapComponent,
    PostPipe,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    TagInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    AuthService,
    UserService,
    ProjectService,
    BoardService,
    PostService,
    TagService,
    CommentService,
    ChangelogService,
    RoadmapService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
