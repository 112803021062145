import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BoardService } from 'src/app/services/board.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { TagService } from 'src/app/services/tag.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css'],
})
export class UserSettingsComponent implements OnInit {
  user: User;
  boards: Board[] = [];
  showLoginModal: boolean = false;
  loginDropdown: boolean = false;
  showRegisterModal: boolean = false;
  boardId: string;
  active_status = 1;
  project: Project;
  showDeleteUser: boolean = false;
  board: Board;
  showBoardsMenu: boolean = false;
  active:string = '';

  constructor(
    private userService: UserService,
    private boardService: BoardService,
    private authService: AuthService,
    private tagService: TagService,
    private projectService: ProjectService,
    private postService: PostService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.active = router.url;

  }

  async ngOnInit() {
    this.boardId = this.route.snapshot.paramMap.get('id');
    this.board = await this.boardService.getBoard(this.boardId);
    this.project = await this.projectService.getProject(this.board.projectID);

    this.user = await this.userService.getUser();

    for (let boardId of this.project.boards) {
      const projectBoard = await this.boardService.getBoard(boardId);
      if (projectBoard.settings.visibility) {
        this.boards.push(projectBoard);
      }
    }

    this.user.notification = this.user.notification || {
      newComment: true,
      newStatus: true,
      newTag: true,
    };
  }

  dropdownHandler() {
    this.showBoardsMenu = !this.showBoardsMenu;
  }

  openChangelog() {
    this.router.navigate(['/' + this.boardId + '/changelog']);
  }

  openBoard(board: Board) {
    this.router.navigate(['/' + board.id]);
  }

  public closeModal() {
    var Notification = document.getElementById('notification');
    Notification.style.transform = 'translateX(150%)';
  }

  async save() {
    console.log(this.user);
    await this.userService.setUser(this.user);
    var Notification = document.getElementById('notification');
    Notification.style.transform = 'translateX(150%)';
    Notification.classList.remove('hidden');
    setTimeout(function () {
      Notification.style.transform = 'translateX(0%)';
    }, 1000);
  }

  async closeRegisterModal() {
    this.showRegisterModal = false;
    this.loginDropdown = false;
    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
  }

  async logout() {
    await this.authService.signOut();
    this.user = null;
    this.loginDropdown = false;
  }

  openSettings() {
    this.router.navigate(['/settings/' + this.user.id]);
  }

  openFeatureBear() {
    window.open('https://featurebear.com', '_blank');
  }

  deleteUser() {
    this.showLoginModal = true;
  }

  async closeLoginModal() {
    for (let projectId of this.user.projects) {
      const project: Project = await this.projectService.getProject(projectId);

      for (let boarId of project.boards) {
        const board = await this.boardService.getBoard(boarId);
        for (let tagId of board.tags) {
          await this.tagService.deleteTag(tagId);
        }

        for (let postId of board.posts) {
          await this.postService.deletePost(postId);
        }

        for (let userId of board.registeredUsers) {
          let user: User = await this.userService.getUserById(userId);
          user.boards = user.boards.filter((boardId) => boardId !== board.id);
          await this.userService.setUser(user);
        }

        await this.boardService.deleteBoard(board.id);
      }
      await this.projectService.deleteProject(projectId);
    }

    await this.userService.deleteUser(this.user.id);

    await this.authService.deleteUser(this.user.id);
    this.router.navigate(['/login']);
  }

  openRoadmap(){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['/' + this.boardId + '/roadmap']);
  }


  back() {
    this.router.navigate(['/' + this.boardId]);
  }
}
