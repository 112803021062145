import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Post } from 'src/app/models/post.model';
import { Project } from 'src/app/models/project.model';
import { Tag } from 'src/app/models/tag.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BoardService } from 'src/app/services/board.service';
import { PostService } from 'src/app/services/post.service';
import { ProjectService } from 'src/app/services/project.service';
import { TagService } from 'src/app/services/tag.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css'],
})
export class TagComponent implements OnInit {
  flag = true;
  showAddFeature: boolean = false;
  showMenu: boolean = false;
  tagId: string;
  tag: Tag;
  posts: Post[];
  originalPosts: Post[];
  loader: boolean = false;
  user: User;
  boards: Board[] = [];
  showBoardSettings: boolean = false;
  tags: Tag[] = [];
  board: Board;

  showLoginModal: boolean = false;
  loginDropdown: boolean = false;
  showRegisterModal: boolean = false;
  show_list = false;
  filterText: string = '';
  activeSort: string = 'Upvotes';
  showForgotPassword: boolean = false;
  project: Project;
  showBoardsMenu: boolean = false;
  active: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private boardService: BoardService,
    private userService: UserService,
    private postService: PostService,
    private projectService: ProjectService,
    private tagService: TagService,
    private authService: AuthService,
    private http: HttpClient
  ) {
    this.active = router.url;
  }

  async ngOnInit() {
    this.loader = true;
    this.tagId = this.route.snapshot.paramMap.get('id');
    this.tag = await this.tagService.getTag(this.tagId);

    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }

    this.board = await this.boardService.getBoard(this.tag.boardId);
    this.project = await this.projectService.getProject(this.board.projectID);

    this.route.params.subscribe(async (params: Params) => {
      this.tagId = params['id'];
      this.loadPosts();
    });

    this.boards = this.boardService.getBoards();
    if (typeof this.boards === 'undefined') {
      this.boards = [];
      let board = await this.boardService.getBoard(this.tag.boardId);
      let project = await this.projectService.getProject(board.projectID);
      project.boards.forEach(async (boardId) => {
        this.boards.push(await this.boardService.getBoard(boardId));
      });
      this.boardService.setBoards(this.boards);
    }
  }

  dropdownHandler() {
    this.showBoardsMenu = !this.showBoardsMenu;
  }

  openBoard(board: Board) {
    this.router.navigate(['/' + board.id]);
  }

  openChangelog() {
    this.router.navigate(['/' + this.board.id + '/changelog']);
  }

  async loadPosts() {
    this.loader = true;
    this.posts = [];
    this.tag = await this.tagService.getTag(this.tagId);
    for (const postId of this.tag.featureRequests) {
      this.posts.push(await this.postService.getPost(postId));
    }
    this.sortPosts();
    this.loader = false;
    this.originalPosts = Object.assign([], this.posts);
  }

  sortPosts() {
    this.posts.sort(function (a, b) {
      return b.upvotes.length - a.upvotes.length;
    });
  }

  filterList() {
    if (this.filterText === '') {
      this.posts = this.originalPosts;
      this.sortBy(this.activeSort);
      return;
    }

    this.posts = Object.assign([], this.originalPosts).filter(
      (item) =>
        item.title.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1
    );
  }

  sortBy(sortString) {
    switch (sortString) {
      case 'Upvotes':
        this.sortPosts();
        break;
      case 'Latest':
        this.posts.sort(function (a, b) {
          const bDate: any = b.date_created as Object;
          const aDate: any = a.date_created as Object;
          return (
            new Date(bDate.seconds * 1000).getTime() -
            new Date(aDate.seconds * 1000).getTime()
          );
        });
        break;
      default:
        break;
    }
    this.show_list = false;
    this.activeSort = sortString;
  }

  async upvote(post: Post) {
    const hasUserUpvoted = post.upvotes.includes(this.user.id);

    if (hasUserUpvoted) {
      post.upvotes = post.upvotes.filter((userId) => userId !== this.user.id);
      this.user.upvotes = this.user.upvotes.filter(
        (postId) => postId !== post.id
      );
    } else {
      this.http.post(environment.nodeUrl + 'new-upvote', {
        board: this.board,
        post: post,
      });
      post.upvotes.push(this.user.id);
      this.user.upvotes.push(post.id);
    }
    await this.postService.setPost(post);
    await this.userService.setUser(this.user);

    this.sortPosts();
  }

  async getTag(tagId) {
    let tag: Tag;

    const isTagLoaded = this.tags.find((t) => t.id === tagId);

    if (isTagLoaded) {
      tag = isTagLoaded;
    } else {
      tag = await this.tagService.getTag(tagId);
    }

    return tag;
  }

  openPost(postId) {
    this.router.navigate(['/post/' + postId]);
  }

  openRoadmap() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['/' + this.board.id + '/roadmap']);
  }

  openTag(tagId) {
    this.router.navigate(['/tag/' + tagId]);
  }

  back() {
    this.router.navigate(['/' + this.tag.boardId]);
  }

  async closeLoginModal() {
    this.showLoginModal = false;
    this.loginDropdown = false;
    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
  }

  async closeRegisterModal() {
    this.showRegisterModal = false;
    this.loginDropdown = false;
    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
  }

  async logout() {
    await this.authService.signOut();
    this.user = null;
    this.loginDropdown = false;
  }

  openSettings() {
    this.router.navigate(['/settings/' + this.board.id]);
  }

  openFeatureBear() {
    window.open('https://featurebear.com', '_blank');
  }
}
