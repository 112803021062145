import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';
import { map, filter, switchMap, catchError } from 'rxjs/operators';

@Pipe({
  name: 'user',
})
export class UserPipe implements PipeTransform {
  constructor(private userService: UserService) {}
  transform(userId: string): Promise<string> | string {
    if (userId === null) {
      return new Promise((resolve, reject) => {
        resolve('Anonymous');
      });
    }

    return this.userService.getUserById(userId).then((user) => {
      let name;
      if (user.firstname === '' || user.surname === '') {
        name = 'Anonymous';
      } else {
        name = user.firstname + ' ' + user.surname;
      }
      return name;
    });
  }
}
