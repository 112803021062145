<!-- This example requires Tailwind CSS v2.0+ -->

<div
  *ngIf="showAddFeature"
  @fadeAnimation
  class="fixed overflow-y-scroll inset-0 z-50"
>
  <div class="absolute inset-0 bg-gray-900 bg-opacity-75 z-0"></div>
  <div class="flex w-full justify-end">
    <div
      class="bg-white w-full md:w-2/5 xl:w-2/5 pt-12 min-h-screen z-50"
      [ngClass]="{
        'px-2': widget,
        'px-12': !widget
      }"
    >
      <div class="container flex flex-col w-full h-full justify-between">
        <div>
          <div class="flex w-full justify-between items-center">
            <h2
              class="font-bold text-dark"
              [ngClass]="{
                'text-lg': widget,
                'text-3xl': !widget
              }"
            >
              Add Post
            </h2>
            <div (click)="close()" class="block ml-5 cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#718096"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z"></path>
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>
          </div>
          <form [formGroup]="postForm" novalidate>
            <div class="mt-10 border-b border-gray-400">
              <label
                for="description"
                class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Title</label
              >
              <input
                formControlName="title"
                [ngClass]="{
                  'border-red-400':
                    postForm.controls['title'].invalid &&
                    (postForm.controls['title'].dirty ||
                      postForm.controls['title'].touched),
                  'text-lg': widget,
                  'text-2xl': !widget
                }"
                class="w-full font-bold pb-2 focus:outline-none placeholder-gray-400"
                placeholder="e.g. Dark Mode"
                type="text"
              />
            </div>

            <div
              [ngClass]="{
                'mt-6': widget,
                'mt-20': !widget
              }"
            >
              <label
                for="description"
                class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Description</label
              >
              <textarea
                formControlName="description"
                [ngClass]="{
                  'border-red-400':
                    postForm.controls['description'].invalid &&
                    (postForm.controls['description'].dirty ||
                      postForm.controls['description'].touched)
                }"
                class="w-full text-lg font-bold pb-2 focus:outline-none placeholder-gray-400"
                placeholder="Why do you request this feature?"
                rows="4"
              ></textarea>
            </div>

            <div
              [ngClass]="{
                'mt-6': widget,
                'mt-20': !widget
              }"
            >
              <label
                for="description"
                class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Tags</label
              >
              <div class="flex justify-start flex-wrap">
                <div
                  *ngFor="let tag of tags"
                  [ngStyle]="getTagStyle(tag)"
                  (click)="addTag(tag)"
                  class="border cursor-pointer m-2 h-6 w-20 mb-4 md:mb-0 rounded-full flex items-center justify-center"
                >
                  <span class="text-xs text-white font-normal">{{
                    tag.name
                  }}</span>
                </div>
              </div>
            </div>
            <div class="mt-10 border-gray-400">
              <label
                for="description"
                class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
              >
                Attachments</label
              >
              <div class="flex flex-col w-auto mt-2 bg-grey-lighter">
                <label
                  class="flex space-x-2 items-center w-40 px-2 py-2 bg-white text-sm text-blue-400 rounded-lg shadow-lg tracking-wide uppercase border border-blue-400 cursor-pointer hover:bg-blue-400 hover:text-white"
                >
                  <svg
                    class="w-5 h-5"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z"
                    />
                  </svg>
                  <span class="text-sm leading-normal">Select a file</span>
                  <input
                    type="file"
                    accept="image/*"
                    (change)="upload($event)"
                    class="hidden"
                  />
                </label>
                <div
                  class="mb-4 mt-2 lg:mb-0 mr-4"
                  *ngFor="let image of images"
                >
                  <img
                    [src]="image"
                    alt=""
                    class=" h-16 w-auto  overflow-hidden shadow"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="mt-4 md:mt-0">
          <div
            class="md:flex justify-between w-full py-4 border-t border-gray-400"
          >
            <div class="py-4 flex items-center">
              <div
                class="bg-white border rounded-sm border-gray-400 w-5 h-5 flex flex-shrink-0 justify-center items-center relative"
              >
                <input
                  [(ngModel)]="postAnonymously"
                  type="checkbox"
                  class="checkbox opacity-0 absolute cursor-pointer w-full h-full"
                />
                <div class="check-icon hidden bg-fb-red text-white rounded-sm">
                  <svg
                    class="icon icon-tabler icon-tabler-check"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M5 12l5 5l10 -10" />
                  </svg>
                </div>
              </div>
              <p class="ml-3 text-base leading-4 font-normal text-gray-800">
                Post anonymously?
              </p>
            </div>
            <div class="mt-4 md:mt-0">
              <button
                (click)="addPost()"
                class="text-md text-white focus:outline-none  px-6 bg-fb-red rounded-md"
                [ngClass]="{
                  'w-full': widget,
                  'py-3': !widget,
                  'py-1': widget
                }"
              >
                Save Post
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
