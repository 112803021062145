<div
  id="member-modal"
  class="fixed z-10 pt-10 left-0 top-0 w-full h-full overflow-auto shadow-lg display: none;"
  style="background-color: rgb(0, 0, 0); background-color: rgba(0, 0, 0, 0.4)"
>
  <div role="alert" class="container mx-auto w-11/12 md:w-2/3 max-w-lg">
    <div
      class="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400"
    >
      <div class="w-full flex justify-start text-gray-600 mb-3">
        <img src="assets/fba-logo.png" class="h-12 w-auto"/>
      </div>
      <form [formGroup]="projectForm" novalidate>

      <h1
        class="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4"
      >
        Create Projekt
      </h1>
      <label
        for="name"
        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
      >
        Name</label
      >
      <input
        id="name"
        formControlName="name"
        class="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
        placeholder="Projekt X"
        [ngClass]="{
          'border-red-400':
          projectForm.controls['name'].invalid && (projectForm.controls['name'].dirty || projectForm.controls['name'].touched)
        }"
      />
      <label
        for="description"
        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
      >
        Description</label
      >
      <div class="relative mb-5 mt-2">
        <div
          class="absolute right-0 text-gray-600 flex items-center pr-3 h-full"
        >
        </div>
        <textarea
          id="description"
          rows="3"
          formControlName="description"
          class="text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full  flex items-center pl-3 text-sm border-gray-300 rounded border"
          placeholder="Optional"
        ></textarea>
      </div>

      <div class="flex items-center justify-start w-full">
        <button (click)="close()"
          class="cancel-member focus:outline-none bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
        >
          Close
        </button>
        <button  (click)="addProject()"
          class="focus:outline-none ml-3 transition duration-150 ease-in-out  bg-fb-red rounded text-white px-8 py-2 text-sm"
        >
          Create
        </button>
      </div>
      </form>
      <svg
        (click)="close()"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Close"
        class="close-member cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out icon icon-tabler icon-tabler-x"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        stroke-width="2.5"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </div>
  </div>
</div>
