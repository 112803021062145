import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Post } from '../models/post.model';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  constructor(private db: AngularFirestore, private afStorage: AngularFireStorage) {}

  async getPost(postId) {
    const snapshot = await this.db.collection('posts').ref.doc(postId).get();
    const post = snapshot.data() as Post;
    return post;
  }

  async getPostOfBoard(boardId) {
    const snapshot = await this.db
      .collection('posts')
      .ref.where('boardId', '==', boardId)
      .get();
    let posts = [];
    snapshot.forEach((post) => {
      posts.push(post.data());
    });
    return posts;
  }

  async getPostByStatusOfBoard(boardId, status) {
    const snapshot = await this.db
      .collection('posts')
      .ref.where('boardId', '==', boardId)
      .where('status', '==', status)
      .get();
    let posts = [];
    snapshot.forEach((post) => {
      posts.push(post.data());
    });
    return posts;
  }

  async createPost(post: Post) {
    const id = this.db.collection('posts').doc().ref.id;
    post.id = id;
    await this.db.collection('posts').doc(id).set(post);
    return id;
  }

  async setPost(post: Post) {
    return this.db
      .collection('posts')
      .doc(post.id)
      .set({ ...post });
  }

  async deletePost(postId) {
    await this.db.collection('posts').doc(postId).delete();
  }

  uploadPostImage(randomId,picture) {
    return this.afStorage.upload('/post/'+randomId, picture);  
   }
}
