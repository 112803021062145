import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Board } from 'src/app/models/board.model';
import { Post } from 'src/app/models/post.model';
import { Project } from 'src/app/models/project.model';
import { Changelog } from 'src/app/models/changelog.model';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { BoardService } from 'src/app/services/board.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user.service';
import { ChangelogService } from 'src/app/services/changlog.service';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.css'],
})
export class ChangelogComponent implements OnInit {
  flag = true;
  showAddFeature: boolean = false;
  showMenu: boolean = false;
  boardId: string;
  changelogId: string;
  board: Board;
  posts: Post[];
  originalPosts: Post[];
  loader: boolean = false;
  user: User;
  boards: Board[] = [];
  showBoardSettings: boolean = false;
  loginDropdown: boolean = false;
  showLoginModal: boolean = false;
  showRegisterModal: boolean = false;
  isAdmin: boolean = false;
  show_list = false;
  filterText: string = '';
  activeSort: string = 'Upvotes';
  showBoardList: boolean = false;
  project: Project;
  showForgotPassword: boolean = false;
  changelogs: Changelog[] = [];
  showBoardsMenu: boolean = false;
  active: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private boardService: BoardService,
    private userService: UserService,
    private projectService: ProjectService,
    private authService: AuthService,
    private http: HttpClient,
    private changelogService: ChangelogService
  ) {
    this.active = router.url;
  }

  async ngOnInit() {
    this.loader = true;
    this.boardId = this.route.snapshot.paramMap.get('id');
    this.changelogId = this.route.snapshot.paramMap.get('changelogId');

    if (this.boardId === null) {
      window.location.href = 'https://featurebear.com';
    }

    this.board = await this.boardService.getBoard(this.boardId);

    this.loadBoards();

    if (typeof this.board === 'undefined') {
      window.location.href = 'https://featurebear.com';
    }

    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
    this.isAdmin = this.user ? this.board.admins.includes(this.user.id) : false;
  }

  dropdownHandler() {
    this.showBoardsMenu = !this.showBoardsMenu;
  }

  openChangelog() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['/' + this.boardId + '/changelog']);
  }

  async loadBoards() {
    this.project = await this.projectService.getProject(this.board.projectID);

    if (typeof this.changelogId === 'undefined' || this.changelogId === null) {

      this.project.changelog = this.project.changelog || []


      for (let changelogId of this.project.changelog) {
        let changelogItem = await this.changelogService.getChangelog(
          changelogId
        );
        if (changelogItem.status) {
          this.changelogs.push(changelogItem);
        }
      }
    } else {
      let changelogItem = await this.changelogService.getChangelog(
        this.changelogId
      );
      if (changelogItem.status) {
        this.changelogs.push(changelogItem);
      }
    }
    this.changelogs.reverse();
    this.loader = false;

    this.project.settings = this.project.settings || {
      linkBoards: false,
      logo: '',
    };

    for (let boardId of this.project.boards) {
      const projectBoard = await this.boardService.getBoard(boardId);
      if (projectBoard.settings.visibility) {
        this.boards.push(projectBoard);
      }
    }
  }

  openBoard(board: Board) {
    this.router.navigate(['/' + board.id]);
  }

  async closeSettings() {
    this.showBoardSettings = false;
    //await this.loadPosts();
  }

  openFeatureBear() {
    window.open('https://featurebear.com', '_blank');
  }

  async closeLoginModal() {
    this.showLoginModal = false;
    this.loginDropdown = false;
    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
  }

  async closeRegisterModal() {
    this.showRegisterModal = false;
    this.loginDropdown = false;
    if (await this.authService.isLoggedIn()) {
      this.user = await this.userService.getUser();
    }
  }

  openSettings() {
    this.router.navigate(['/settings/' + this.board.id]);
  }

  async logout() {
    await this.authService.signOut();
    this.user = null;
    this.loginDropdown = false;
  }

  getEncodedUrl(changelogId) {
    return window.encodeURIComponent(
      'https://app.featurebear.com/' +
        this.boardId +
        '/changelog/' +
        changelogId
    );
  }

  copyUrl(changelogId) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value =
      'https://app.featurebear.com/' +
      this.boardId +
      '/changelog/' +
      changelogId;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    var Notification = document.getElementById('notification');
    Notification.style.transform = 'translateX(150%)';
    Notification.classList.remove('hidden');
    Notification.style.transform = 'translateX(0%)';
    setTimeout(() => {
      this.closeModal();
    }, 3000);
  }

  closeModal() {
    var Notification = document.getElementById('notification');
    Notification.style.transform = 'translateX(150%)';
    Notification.classList.add('hidden');
  }

  openRoadmap(){
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['/' + this.board.id + '/roadmap']);
  }
}
