<app-login-dialog
  *ngIf="showLoginModal"
  (openSignUpModal)="showRegisterModal = true; showLoginModal = false"
  (closeModal)="closeLoginModal()"
  (openResetPasswordModal)="showForgotPassword = true; showLoginModal = false"
  [board]="board"
></app-login-dialog>

<app-register-dialog
  *ngIf="showRegisterModal"
  (openSignInModal)="showLoginModal = true; showRegisterModal = false"
  (closeModal)="closeRegisterModal()"
  [board]="board"
></app-register-dialog>

<app-forgot-password
  *ngIf="showForgotPassword"
  (closeModal)="showForgotPassword = false"
></app-forgot-password>
<nav class="w-full bg-transparent" *ngIf="!widget">
  <div class="m-4 h-16 flex justify-between">
    <div class="flex items-center">
      <img
        *ngIf="board?.settings.fbLogoVisibility && project"
        (click)="openFeatureBear()"
        [src]="
          project?.settings?.logo
            ? project?.settings?.logo
            : 'assets/fb-red.png'
        "
        class="cursor-pointer pl-4 w-auto h-12"
      />
    </div>
    <ul class="md:flex hidden items-center h-full">
      <li
        class="cursor-pointer h-full flex items-center text-sm text-gray-800 px-3 tracking-normal relative"
        (click)="dropdownHandler()"
        [ngClass]="{
          'text-fb-red': active.includes('board') || active.includes('post')
        }"
      >
        <ul
          *ngIf="showBoardsMenu"
          class="bg-white shadow rounded py-1 w-32 left-0 mt-16 -ml-4 absolute top-0"
        >
          <li
            (click)="openBoard(board)"
            class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-400 hover:text-white px-3 font-normal"
            *ngFor="let board of boards"
          >
            {{ board.name }}
          </li>
        </ul>
        Boards
        <span class="ml-2 text-gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-chevron-down"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <polyline points="6 9 12 15 18 9" />
          </svg>
        </span>
      </li>
      <li
        (click)="openChangelog()"
        class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
        [ngClass]="{
          'text-fb-red': active.includes('changelog')
        }"
      >
        Changelog
      </li>
      <li
        (click)="openRoadmap()"
        class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
        [ngClass]="{
          'text-fb-red': active.includes('roadmap')
        }"
      >
        Roadmap
      </li>
    </ul>
    <div class="relative">
      <div class="relative flex items-center cursor-pointer">
        <div
          class="flex cursor-pointer items-center"
          (click)="loginDropdown = !loginDropdown"
        >
          <svg
            class="h-6 w-6 m-4 text-gray-600"
            *ngIf="user"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p *ngIf="user" class="text-gray-600 mr-4 text-sm">
            {{ user?.firstname }} {{ user?.surname }}
          </p>
        </div>

        <svg
          *ngIf="!user"
          (click)="loginDropdown = !loginDropdown"
          class="h-6 w-6 m-4 text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
          />
        </svg>
      </div>
      <ul
        *ngIf="loginDropdown"
        class="bg-white shadow rounded mt-2 -ml-24 py-1 w-48 absolute"
      >
        <li
          *ngIf="!user"
          (click)="showLoginModal = true"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Login
        </li>
        <li
          *ngIf="!user"
          (click)="showRegisterModal = true"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Register
        </li>
        <li
          *ngIf="user"
          (click)="openSettings()"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Settings
        </li>
        <li
          *ngIf="user"
          (click)="logout()"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:text-red-400 px-3 font-normal"
        >
          Logout
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="md:hidden flex justify-center mb-10" *ngIf="!widget">
  <ul class="flex items-center h-full">
    <li
      class="cursor-pointer h-full flex items-center text-sm text-gray-800 px-3 tracking-normal relative"
      (click)="dropdownHandler()"
    >
      <ul
        *ngIf="showBoardsMenu"
        class="bg-white shadow rounded py-1 w-32 left-0 mt-16 -ml-4 absolute top-0"
      >
        <li
          (click)="openBoard(board)"
          class="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-3 hover:bg-gray-400 hover:text-white px-3 font-normal"
          *ngFor="let board of boards"
        >
          {{ board.name }}
        </li>
      </ul>
      Boards
      <span class="ml-2 text-gray-600">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-chevron-down"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <polyline points="6 9 12 15 18 9" />
        </svg>
      </span>
    </li>
    <li
      (click)="openChangelog()"
      class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
      [ngClass]="{
        'text-fb-red': active.includes('changelog')
      }"
    >
      Changelog
    </li>
    <li
      (click)="openRoadmap()"
      class="cursor-pointer h-full flex items-center text-sm text-gry-800 px-3 tracking-normal"
      [ngClass]="{
        'text-fb-red': active.includes('roadmap')
      }"
    >
      Roadmap
    </li>
  </ul>
</div>
<div class="flex flex-no-wrap">
  <div class="py-8 px-4 md:px-12 w-full">
    <div
      (click)="navigateBack()"
      class="cursor-pointer w-20 mb-6 p-1 border rounded-xl flex items-center"
    >
      <svg
        class="cursor-pointer w-4 h-4 mr-2 text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 16l-4-4m0 0l4-4m-4 4h18"
        />
      </svg>
      <p class="cursor-pointer text-gray-700 font-medium text-sm pr-3">back</p>
    </div>
    <div
      class="flex w-full justify-between items-center mb-6 md:mb-12"
      *ngIf="post"
    >
      <!-- Card is full width. Use in 12 col grid for best view. -->
      <!-- Card code block start -->
      <div
        class="flex flex-col lg:flex-row mx-auto w-full bg-white shadow rounded"
      >
        <div class="w-full p-6">
          <div class="flex flex-col md:flex-row justify-between items-center">
            <div class="">
              <h5 class="text-gray-800 font-medium text-2xl">
                {{ post?.title }}
              </h5>
            </div>

            <a
              (click)="upvote(post)"
              [ngClass]="{
                'bg-green-500 text-white': user?.upvotes.includes(post.id)
              }"
              class="group flex flex-col justify-center items-center mt-6 py-1 w-full sm:w-52 border rounded-md hover:bg-green-500 bg-green-50 cursor-pointer"
            >
              <svg
                class="group-hover:text-white w-6"
                [ngClass]="{
                  'text-white': user?.upvotes.includes(post.id),
                  'text-green-400': !user?.upvotes.includes(post.id)
                }"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 15l7-7 7 7"
                />
              </svg>
              <div
                class="inline-block font-medium text-lg group-hover:text-white"
                [ngClass]="{
                  'text-white': user?.upvotes.includes(post.id),
                  'text-green-400': !user?.upvotes.includes(post.id)
                }"
              >
                {{ post?.upvotes.length }}
              </div>
            </a>
          </div>
          <div
            class="flex flex-col lg:flex-row items-start md:items-center justify-between"
          >
            <div
              class="flex space-y-2 space-x-0 md:space-x-4 lg:flex-row flex-col xs:justify-start md:items-center mt-4 mr-2"
            >
              <div class="flex space-x-2 mt-1">
                <svg
                  class="w-4 h-4 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p class="text-gray-500 text-sm font-normal">
                  {{ post?.date_created.toDate() | date: "medium" }}
                </p>
              </div>
              <div class="flex space-x-2">
                <svg
                  class="w-4 h-4 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                  />
                </svg>
                <p class="text-gray-500 text-sm font-normal">
                  {{ post?.creator | user | async }}
                </p>
              </div>
              <div class="flex">
                <div
                  class="border h-5 w-auto px-2 md:mb-0 rounded-md flex items-center justify-center"
                >
                  <div class="flex items-center">
                    <span class="text-xs text-gray-500 font-normal"
                      >Status: {{ post.status }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="flex space-x-2 cursor-pointer"
                *ngIf="post?.changelog"
                (click)="openChangelogOfPost()"
              >
                <svg
                  class="w-4 h-4 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
                <p class="text-gray-500 text-sm font-normal">
                  {{ post?.changelog | changelog | async }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-span-12 mt-2 lg:col-span-8">
            <a
              *ngFor="let tag of post.tags"
              [ngStyle]="{
                'background-color': tag | tagColor | async
              }"
              (click)="openTag(tag)"
              class="cursor-pointer inline-block rounded-full text-white duration-300 text-xs font-bold mr-1 md:mr-2 mb-2 px-2 md:px-4 py-1 opacity-90 hover:opacity-100"
            >
              {{ tag | tag | async }}
            </a>
          </div>
          <h3
            class="text-lg text-gray-800 font-bold mt-5 mb-1"
            *ngIf="post.attachments?.length > 0"
          >
            Attachment
          </h3>
          <div class="mb-4 flex space-x-2 mt-2 lg:mb-0 mr-4">
            <img
              (click)="openImage(image)"
              *ngFor="let image of post.attachments"
              [src]="image"
              alt=""
              class="h-16 w-auto overflow-hidden shadow cursor-pointer"
            />
          </div>
          <h3
            class="text-lg text-gray-800 font-bold mt-5 mb-1"
            *ngIf="post?.description"
          >
            Description
          </h3>
          <p class="text-gray-600 text-sm font-normal">
            {{ post?.description }}
          </p>
        </div>
        <div class="ml-4 mb-2 mt-20 text-gray-800 font-medium text-xl">
          Comments
        </div>
        <div class="flex w-full justify-between items-center mb-6 md:mb-12">
          <div class="w-full shadow-md rounded overflow-hidden">
            <div
              class="bg-white rounded flex w-full items-center py-5 border-b-2 border-gray-200"
            >
              <input
                type="text"
                [(ngModel)]="commentText"
                class="text-md px-6 text-gray-500 w-full focus:outline-none"
                placeholder="Your comment"
                (keyup.enter)="addComment()"
              />
              <div
                (click)="addComment()"
                class="p-4 cursor-pointer flex items-center border-l border-gray-300 flex-col justify-center text-gray-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-chevron-up"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            </div>
            <div class="rounded px-6 md:px-12 py-5 md:py-10 bg-gray-100">
              <div
                class="flex items-center"
                (click)="showComments = !showComments"
              >
                <p class="text-md font-semibold leading-loose text-gray-500">
                  {{ comments.length }} Comments
                </p>
                <svg
                  *ngIf="showComments"
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 md:w-auto cursor-pointer text-gray-600 icon icon-tabler icon-tabler-chevron-down"
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <polyline points="6 9 12 15 18 9" />
                </svg>

                <svg
                  *ngIf="!showComments"
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 md:w-auto cursor-pointer text-gray-600 icon icon-tabler icon-tabler-chevron-down"
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              </div>
              <div *ngIf="showComments">
                <div
                  class="mt-7 md:mt-14 md:flex items-start"
                  *ngFor="let comment of comments"
                >
                  <div class="mt-5 w-full md:mt-0">
                    <div class="flex w-full justify-between">
                      <p class="text-sm font-semibold text-gray-800">
                        {{ comment.user | user | async }}
                        <span class="text-gray-500 font-normal"
                          >&nbsp;&nbsp;&nbsp;
                          {{ comment.date.toDate() | date: "medium" }}</span
                        >
                      </p>
                    </div>
                    <p class="text-sm text-gray-500">{{ comment.comment }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
